import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

interface MapboxLayerToggleProps {
    defaultDisabled?: boolean;
    onClick?: () => void;
    icon: IconDefinition;
    onMouseEnter?: () => void;
    title?: string;
}

export const LayerToggle: React.FC<MapboxLayerToggleProps> = ({
    defaultDisabled,
    icon,
    onClick,
    onMouseEnter,
    title,
}) => {
    const [enabled, setEnabled] = useState(defaultDisabled === undefined ? true : !defaultDisabled);
    
    return (
        <OverlayTrigger
            placement="left"
            overlay={
                <Tooltip id={title || ' '}>
                    {title}
                </Tooltip>
            }
        >
            <button
                type="button"
                // title={title}
                onClick={() => {
                    setEnabled(!enabled);
                    onClick && onClick();
                }}
                onMouseEnter={onMouseEnter}
            >
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={icon} color={!enabled ? '#CCC' : undefined}/>
                    {!enabled && (<FontAwesomeIcon icon={faSlash} color="#CCC" />)}
                </span>
            </button>
        </OverlayTrigger>
    )
}