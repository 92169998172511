import { useCallback, useEffect, useState } from 'react';
import './style.css';
import 'react-tabs/style/react-tabs.css';
import 'react-datetime/css/react-datetime.css';
import { RawCSVTransaction, Transaction } from './types';
import { ParseCSVText } from '../../lib/Utils/CSV';
import TransactionTable from './TransactionTable';
import Charts, { AccountsCategories } from './Charts';
import Datetime from 'react-datetime';
import { Button, Form, Modal, ModalBody } from 'react-bootstrap';
import { formatter } from '../../lib/d3/common';
import SavingsChart from './SavingsChart';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ShrinkingSideMenu } from 'src/components/ShrinkingSideMenu';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import { ToISOStringLocal } from '../Running/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faChartLine, faDollarSign, faReceipt, faTable, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import BalanceTable from './BalanceTable';
import StackedBarChart from 'src/lib/d3/StackedBarChart';

const ConvertRawTransaction = (rawTransaction: RawCSVTransaction): Transaction|null => {
    if (rawTransaction.Account === undefined) {
        return null;
    }
    const dateParts = rawTransaction.Date.split('-');
    const transactionDate = new Date();
    transactionDate.setFullYear(parseInt(dateParts[0]));
    transactionDate.setMonth(parseInt(dateParts[1]) - 1);
    transactionDate.setDate(parseInt(dateParts[2]));
    transactionDate.setHours(0);
    transactionDate.setMinutes(0);
    transactionDate.setMilliseconds(0);
    return {
        account: rawTransaction.Account,
        category: rawTransaction.Category,
        date: transactionDate,
        description: rawTransaction.Description,
        originalDescription: rawTransaction['Original Description'],
        value: parseFloat(rawTransaction.Value)
    }
}

const firstDayOfMonth = (input: Date) => {
    const firstDay = new Date(input);
    firstDay.setDate(1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);
    firstDay.setSeconds(0);
    firstDay.setMilliseconds(0);
    return firstDay;
}

interface ModalInformation {
    category: string;
    total: number;
    date: Date;
    transactions: Transaction[];
}

interface FinanceView {
    color: string;
    icon: IconDefinition;
}

enum VIEW {
    TRANSACTIONS_TABLE = 'Transactions Table',
    BALANCES_TABLE = 'Balances Table',
    RUNNING_BALANCE_CHARTS = 'Running Balance Charts',
    MONTHLY_NET_BAR_CHART = 'Monthly Net Bar Chart',
    SAVINGS_RATE_GRAPH = 'Savings Rate Graph',
}

const VIEWS: { [key in VIEW] : FinanceView } = {
    'Transactions Table': {
        color: '#8E987D',
        icon: faReceipt,
    },
    'Balances Table': {
        color: '#6E5D59',
        icon: faTable,
    },
    'Running Balance Charts': {
        color: '#7496ED',
        icon: faChartLine,
    },
    'Monthly Net Bar Chart': {
        color: '#F0917C',
        icon: faChartBar,
    },
    'Savings Rate Graph': {
        color: '#BEED74',
        icon: faDollarSign,
    }
}

const App: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);

    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
    const [accounts, setAccounts] = useState<Set<string>>();
    const [categories, setCategories] = useState<Set<string>>();
    const [filteredCategories, setFilteredCategories] = useState<Set<string>>(new Set());
    const [accountsCategories, setAccountsCategories] = useState<AccountsCategories>();
    const [balances, setBalances] = useState<Map<Date, Map<string, number>>>(new Map());
    const [expensesMap, setExpensesMap] = useState<Map<Date,Map<string,number>>>();
    const [filteredAccounts, setFilteredAccounts] = useState<Set<string>>(new Set());
    const [filteredBalances, setFilteredBalances] = useState<Map<Date, Map<string, number>>>();
    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(new Date());
    const [valueFilter, setValueFilter] = useState([0, 0]);
    const [valueDomain, setValueDomain] = useState([0, 0]);
    const [finalTransactionDate, setFinalTransactionDate] = useState(new Date());
    const [initialTransactionDate, setInitialTransactionDate] = useState(new Date());
    const [modalInformation, setModalInformation] = useState<ModalInformation|null>(null);

    const [dataView, setDataView] = useState<VIEW>(() => {
        const view = query.get('view');
        if (view) {
            const querySelectedDataView = Object.values(VIEW).find(dataView => dataView === view);
            if (querySelectedDataView) {
                return querySelectedDataView as VIEW;
            }
            toast.error(`Unsupported view in url: ${view}`);
        }
        return VIEW.TRANSACTIONS_TABLE;
    });

    const load = useCallback(async () => {
        const transactionsCsv = await (await fetch('./data/updated.csv')).text();
        const parsedCsvTransactions = ParseCSVText(transactionsCsv);
        const convertedTransactions = parsedCsvTransactions.map(parsedCsvTransaction => {
            return ConvertRawTransaction(parsedCsvTransaction);
        }).filter(a => a !== null) as Transaction[];
        setTransactions(convertedTransactions);
        const accountsSet: Set<string> = new Set();
        const categoriesSet: Set<string> = new Set();
        const balanceMap: Map<Date, Map<string, number>> = new Map();
        let currentMap = new Map();
        let currentDate = convertedTransactions[0].date;
        let valueMin = 0;
        let valueMax = 0;
        balanceMap.set(currentDate, currentMap);
        for (var i = 0; i < convertedTransactions.length; i++) {
            const { account, value, date, category } = convertedTransactions[i];
            if (!accountsSet.has(account)) {
                accountsSet.add(account);
            }
            if (!categoriesSet.has(category)) {
                categoriesSet.add(category);
            }
            if (date.getTime() !== currentDate.getTime()) {
                currentDate = date;
                currentMap = new Map(currentMap);
                balanceMap.set(currentDate, currentMap);
            }

            if (!currentMap.has(account)) {
                currentMap.set(account, 0);
            }
            const previousValue = currentMap.get(account);
            if (previousValue !== undefined) {
                const currentValue = previousValue + value;
                currentMap.set(account, currentValue);
            }
            if (value > valueMax) {
                valueMax = value;
            }
            else if (value < valueMin) {
                valueMin = value;
            }
        }
        setAccounts(accountsSet);
        setFilteredAccounts(new Set(accountsSet));
        setBalances(balanceMap);
        const lastTransactionDate = convertedTransactions[convertedTransactions.length - 1].date;
        const twoYearsBehindLastTransactionDate = new Date(lastTransactionDate);
        twoYearsBehindLastTransactionDate.setFullYear(twoYearsBehindLastTransactionDate.getFullYear() - 2);
        twoYearsBehindLastTransactionDate.setDate(1);
        setFinalTransactionDate(lastTransactionDate);
        setInitialTransactionDate(convertedTransactions[0].date);
        setRangeEnd(lastTransactionDate);
        setRangeStart(twoYearsBehindLastTransactionDate);
        setValueDomain([valueMin, valueMax]);
        setValueFilter([valueMin, valueMax]);
        setCategories(categoriesSet);
        setFilteredCategories(categoriesSet);
        setAccountsCategories(await (await fetch('./data/accounts.json')).json())
    }, []);

    const updateHistory = useCallback((searchParams: { [key: string]: string }) => {
        const pathname = location.pathname;
        const search = `?${new URLSearchParams(searchParams).toString()}`;
        history.push({ pathname, search });
    }, [location.pathname, history])

    useEffect(() => {
        const searchParams: { [key: string]: string } = {
            start: ToISOStringLocal(rangeStart),
            end: ToISOStringLocal(rangeEnd),
            view: dataView,
        };
        updateHistory(searchParams);
    }, [dataView, rangeStart, rangeEnd, updateHistory]);

    useEffect(() => {
        if (filteredTransactions.length === 0) return;
        let expenses: Map<Date, Map<string, number>> = new Map();
        let currentMonthDate = firstDayOfMonth(filteredTransactions[0].date);

        for (var i = 0; i < filteredTransactions.length; i++) {
            const { value, date, category } = filteredTransactions[i];
            if (firstDayOfMonth(date).getTime() !== currentMonthDate.getTime()) {
                currentMonthDate = firstDayOfMonth(date);
            }
            if (!expenses.has(currentMonthDate)) {
                expenses.set(currentMonthDate, new Map());
            }
            const yearMonthMap = expenses.get(currentMonthDate);
            yearMonthMap?.set(category, (yearMonthMap.get(category) || 0) + value);
        }
        const all_categories = [...expenses.values()].reduce((accumulator: string[], entry) => {
            const categories: string[] = Array.from(entry.keys());
            for (var i = 0; i < categories.length; i++) {
                const category = categories[i];
                if (accumulator.indexOf(category) < 0) {
                    accumulator.push(category);
                }
            }
            return accumulator;
        }, []);
        for (const entry of expenses.values()) {
            for (var j = 0; j < all_categories.length; j++) {
                const category = all_categories[j];
                if (!entry.has(category)) {
                    entry.set(category, 0);
                }
            }
        }
        setExpensesMap(expenses);
    }, [filteredTransactions]);

    useEffect(() => {
        setFilteredTransactions(transactions.filter(transaction => {
            return transaction.date >= rangeStart &&
                transaction.date <= rangeEnd &&
                transaction.value >= valueFilter[0] &&
                transaction.value <= valueFilter[1] &&
                filteredCategories.has(transaction.category) &&
                filteredAccounts.has(transaction.account);
        }));
        const currentFilteredBalances = new Map(
            [...balances]
            .filter(([k, v]) => {
                return k >= rangeStart &&
                    k <= rangeEnd;
            })
            .map(([k, v]) : [Date, Map<string, number>]  => {
                const filteredBalancesForDate = new Map(
                    [...v]
                        .filter(([account, _]) => filteredAccounts.has(account))
                );
                const result: [Date, Map<string,number>] = [k, filteredBalancesForDate];
                return result;
            })
        );
        setFilteredBalances(currentFilteredBalances);
    }, [transactions, balances, rangeStart, rangeEnd, valueFilter, filteredAccounts, filteredCategories]);

    useEffect(() => {
        load();
    }, [load]);

    const renderView = useCallback(() => {
        switch (dataView) {
            case VIEW.TRANSACTIONS_TABLE:
                return <div style={{ margin: '0px 0px 0px 60px', flex: '1 0 auto' }}>
                    {filteredTransactions &&
                        <TransactionTable transactions={filteredTransactions} />
                    }
                </div>
            case VIEW.BALANCES_TABLE:
                return <div style={{ margin: '0px 0px 0px 60px', flex: '1 0 auto' }}>
                    {filteredBalances && (
                        <BalanceTable
                            accounts={filteredAccounts}
                            balances={filteredBalances}
                        />
                    )}
                </div>
            case VIEW.RUNNING_BALANCE_CHARTS:
                return <div style={{ margin: '0px 0px 0px 60px', flex: '1 0 auto' }}>
                    {filteredBalances && accountsCategories && (
                        <Charts
                            balances={filteredBalances}
                            accountsCategories={accountsCategories}
                        />
                    )}
                </div>
            case VIEW.MONTHLY_NET_BAR_CHART:
                return <div style={{ margin: '0px 0px 0px 60px', flex: '1 0 auto' }}>
                    {expensesMap && 
                        <StackedBarChart
                            data={expensesMap}
                            stackClicked={(date: Date, category: string, total: number) => {
                                setModalInformation({
                                    category,
                                    date,
                                    total,
                                    transactions: filteredTransactions.filter((t) => {
                                        return t.category === category &&
                                            t.date.getFullYear() === date.getFullYear() &&
                                            t.date.getMonth() === date.getMonth()
                                    }),
                                });
                            }}
                            defaultFilteredCategories={["Investments", "Taxes", "Transfer", "Credit Card Payment"]}
                            yAxisFormat={(a: any) => formatter.format(a)}
                            colorMap={new Map([
                                ["Auto & Transport", "#222"],
                                ["Clothing", "#80b1d3"],
                                ["Dog", "#e5c494"],
                                ["Entertainment", "#bebada"],
                                ["Food & Drink", "#fb8072"],
                                ["Gift", "#fccde5"],
                                ["Health & Wellness", "#8dd3c7"],
                                ["Home & Utilities", "#d9d9d9"],
                                ["Income", "#b3de69"],
                                ["Investments", "#ccebc5"],
                                ["Taxes", "#bc80bd"],
                                ["Vacation", "#ffed6f"]
                            ])}
                            domainPadding={1000}
                        />
                    }
                </div>
            case VIEW.SAVINGS_RATE_GRAPH:
                return <div style={{ margin: '0px 0px 0px 60px', flex: '1 0 auto' }}>
                    {expensesMap &&
                        <SavingsChart data={expensesMap} />
                    }
                </div>
            default:
                return null;
        }
    }, [dataView, expensesMap, accountsCategories, filteredAccounts, filteredBalances, filteredTransactions]);

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <ShrinkingSideMenu
                menuItems={Object.values(VIEW).map(view=> {
                    const { icon, color } = VIEWS[(view as VIEW)];
                    return {
                        icon: (
                            <FontAwesomeIcon
                                icon={icon}
                                inverse={true}
                                size="2x"
                                style={{
                                    display: 'block',
                                    height: '100%',
                                    margin: 'auto',
                                }}
                            />
                        ),
                        title: (
                            <span
                                style={{
                                    color: '#FFF',
                                }}
                            >
                                {view}
                            </span>
                        ),
                        key: view,
                        color: color,
                        onClick: () => {
                            setDataView(view as VIEW)
                        },
                    };
                })}
            >
                <div
                    style={{ margin: '10px 10px' }}
                >
                    <h3 style={{ color: '#EEE' }}>Date Range</h3>
                    <div style={{ display: 'flex' }}>
                        <div style={{ margin: '0 5px', flex: '1 0 0' }}>
                            <h6 style={{ color: '#EEE' }}>Starting Month</h6>
                            <Datetime
                                dateFormat="YYYY-MM"
                                timeFormat={false}
                                onChange={(updatedYearMonth) => {
                                    if (typeof updatedYearMonth !== 'string') {
                                        const start = updatedYearMonth.toDate();
                                        
                                        start.setDate(1);
                                        start.setHours(0);
                                        start.setMinutes(0);
                                        start.setSeconds(0);
                                        start.setMilliseconds(0);
                                        setRangeStart(start);
                                    }
                                }}
                                value={rangeStart}
                                isValidDate={(currentDate: moment.Moment, selectedDate: moment.Moment) => currentDate.isBetween(initialTransactionDate, finalTransactionDate, undefined, "[]")}
                                inputProps={{ style: { display: 'inline' }}}
                            />
                        </div>
                        <div style={{ margin: '0 5px', flex: '1 0 0' }}>
                            <h6 style={{ color: '#EEE' }}>Ending Month</h6>
                            <Datetime
                                dateFormat="YYYY-MM"
                                timeFormat={false}
                                onChange={(updatedYearMonth) => {
                                    if (typeof updatedYearMonth !== 'string') {
                                        const end = updatedYearMonth.toDate();
                                        end.setMonth(end.getMonth() + 1);
                                        end.setDate(0);
                                        end.setHours(0);
                                        end.setMinutes(0);
                                        end.setSeconds(0);
                                        end.setMilliseconds(0);
                                        setRangeEnd(end);
                                    }
                                }}
                                value={rangeEnd}
                                isValidDate={(currentDate: moment.Moment, selectedDate: moment.Moment) => currentDate.isBetween(initialTransactionDate, finalTransactionDate, undefined, "[]")}
                                inputProps={{ style: { display: 'inline' }}}
                            />
                        </div>
                    </div>
                    <h3 style={{ color: '#EEE', margin: '10px 0' }}>
                        Transaction Value
                        <span style={{ float: 'right' }}>
                            <Button
                                style={{ marginRight: '3px' }}
                                size="sm"
                                variant='outline-danger'
                                onClick={() => {
                                    setValueFilter([valueDomain[0], 0]);
                                }}
                                children="Only Debits"
                            />
                            <Button
                                style={{ marginRight: '3px' }}
                                size="sm"
                                variant='outline-success'
                                onClick={() => {
                                    setValueFilter([0, valueDomain[1]]);
                                }}
                                children="Only Credits"
                            />
                            <Button
                                size="sm"
                                variant='outline-primary'
                                onClick={() => {
                                    setValueFilter([valueDomain[0],valueDomain[1]]);
                                }}
                            >
                                Reset
                            </Button>
                        </span>
                    </h3>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <div style={{ flex: '1 0 0', margin: '0 5px' }}>
                            <Form.Control
                                type="number"
                                value={valueFilter[0]}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValueFilter([+event.currentTarget.value, valueFilter[1]])
                                }}
                                max={valueFilter[1]}
                                min={valueDomain[0]}
                            />
                        </div>
                        <div style={{ flex: '1 0 0', margin: '0 5px' }}>
                            <Form.Control
                                type="number"
                                value={valueFilter[1]}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValueFilter([valueFilter[0], +event.currentTarget.value])
                                }}
                                max={valueDomain[1]}
                                min={valueFilter[0]}
                            />
                        </div>
                    </div>
                    <h3 style={{ color: '#EEE', margin: '10px 0' }}>
                        Accounts
                        <span style={{ float: 'right' }}>
                            <Button
                                size="sm"
                                style={{ marginRight: '5px' }}
                                variant='outline-primary'
                                onClick={() => {
                                    setFilteredAccounts(new Set(accounts));
                                }}
                            >
                                Select All
                            </Button>
                            <Button
                                size="sm"
                                variant='outline-primary'
                                onClick={() => {
                                    setFilteredAccounts(new Set());
                                }}
                            >
                                Clear
                            </Button>
                        </span>
                    </h3>
                    <div style={{ color: '#EEE', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '5px' }}>
                        {accounts && Array.from(accounts).map(account => {
                            return (<Form.Switch
                                key={`${account}-switch`}
                                id={`${account}-switch`}
                                label={account}
                                checked={filteredAccounts.has(account)}
                                onChange={() => {
                                    const updatedFilteredAccounts = new Set([...accounts].filter(a => filteredAccounts.has(a) || a === account));
                                    if (filteredAccounts.has(account)) {
                                        updatedFilteredAccounts.delete(account);
                                    } else {
                                        updatedFilteredAccounts.add(account);
                                    }
                                    setFilteredAccounts(updatedFilteredAccounts);
                                }}
                            />);
                        })}
                    </div>
                    <h3 style={{ color: '#EEE', margin: '10px 0' }}>
                        Categories
                        <span style={{ float: 'right' }}>
                            <Button
                                size="sm"
                                style={{ marginRight: '5px' }}
                                variant='outline-primary'
                                onClick={() => {
                                    setFilteredCategories(new Set(categories));
                                }}
                            >
                                Select All
                            </Button>
                            <Button
                                size="sm"
                                variant='outline-primary'
                                onClick={() => {
                                    setFilteredCategories(new Set());
                                }}
                            >
                                Clear
                            </Button>
                        </span>
                    </h3>
                    <div style={{ color: '#EEE', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '5px' }}>
                        {categories && Array.from(categories).map(category => {
                            return (<Form.Switch
                                key={`${category}-switch`}
                                id={`${category}-switch`}
                                label={category}
                                checked={filteredCategories.has(category)}
                                onChange={() => {
                                    const updatedFilteredCategories = new Set([...categories].filter(a => filteredCategories.has(a) || a === category));
                                    if (filteredCategories.has(category)) {
                                        updatedFilteredCategories.delete(category);
                                    } else {
                                        updatedFilteredCategories.add(category);
                                    }
                                    setFilteredCategories(updatedFilteredCategories);
                                }}
                            />);
                        })}
                    </div>
                </div>
            </ShrinkingSideMenu>
            {renderView()}
            <Modal
                show={modalInformation !== null}
                onHide={() => { setModalInformation(null); }}
                size="xl"
            >
                <ModalHeader closeButton={true}>
                    <div style={{ display: 'flex', width: '100%', fontWeight: 'bold' }}>
                        <div>{modalInformation?.category}</div>
                        <div style={{ flexGrow: 1, textAlign: 'center' }}>
                            {`${modalInformation?.date.getFullYear()}-${(modalInformation?.date.getMonth() || 0) < 10 ? '0' : ''}${modalInformation?.date.getMonth()}`}
                        </div>
                        <div>
                            {formatter.format(modalInformation?.total || 0)}
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <TransactionTable
                        transactions={modalInformation?.transactions || []}
                    />
                </ModalBody>
            </Modal>
        </div>
    );
}

export default App;