import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSignal, faCircleNotch, faRoute, faChartLine, faCalendar, faChartArea, faFireAlt, faThLarge, faListAlt, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import * as d3 from 'd3';
import Calendar from './Calendar';
import Circles from './Circles';
import Cumulative from './Cumulative';
import DurationDistance from './DurationDistance';
import { Heatmap } from './MapboxUtils/HeatmapWrapper';
import List from './List';
import NodesProgress, { CityNode } from './NodesProgress/NodesProgress';
import Paths from './Paths';
import Tiles from './Tiles';
import Timeline from './Timeline';
import { ActivityData, DataViewPropsBase, RawActivityData, PathCoordinate, CoordinateBracket } from './types';
import polyline from '@mapbox/polyline';
import { FindRelevantNodesForLatitudeLongitudePoint, MeterDistanceBetweenLatitudeLongitude } from 'src/lib/Utils/Maps';
import { useEffect, useRef } from 'react';

export const CACHE_NAME = 'jrgrover.com/running';

export const COMMON_COLOR_INTERPOLATION = d3.interpolateHsl('rgb(255,237,160)', 'rgb(128,0,38)');
export const COMMON_COLOR_INTERPOLATION_BLACK_WHITE = d3.interpolateHsl('rgb(0,0,0)', 'rgb(255,255,255)');

const API_URI = 'https://jrgrover.com/api';
export const API_ROOT = `${API_URI}/strava`;
const API_PATH = `${API_ROOT}/running`;
export const STRAVA_AUTH_URL = 'https://www.strava.com/oauth/authorize';
const OAUTH_PARAMS = new URLSearchParams({
    client_id: '3614',
    response_type: 'code',
    scope: 'activity:write,activity:read',
    redirect_uri: `${API_ROOT}/token`,
    approval_prompt: 'auto',
});
export const STRAVA_OATUTH_URL = `${STRAVA_AUTH_URL}?${OAUTH_PARAMS.toString()}`;
export const STRAVA_URL = 'https://www.strava.com/activities/';
export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoiam9ncm92ZXIiLCJhIjoiY2lvM2ZzNW9rMDBkcnZpbHp4aXFydnRrZSJ9.PQ5c63LutrKr0tmiyx76uw';
export const MAPBOX_ACCESS_TOKEN_URL = `access_token=${MAPBOX_ACCESS_TOKEN}`;
export const S3_ROOT = 'https://s3.amazonaws.com/jrgrover.com-strava';

const GetAthleteBaseUrl = (athleteId: number) =>
    `${API_PATH}/${athleteId.toString()}`

export const GetAthleteUrl = (athleteId: number, refresh?: boolean) => {
    return `${GetAthleteBaseUrl(athleteId)}${(refresh) ? '?refresh=true' : ''}`;
}

export const GetActivityUrl = (athleteId: number, activityId: number, cache?: boolean) => {
    return `${GetAthleteBaseUrl(athleteId)}/${activityId.toString()}${(cache) ? '?cache=true' : ''}`;
}

const NODES_URI = `${API_URI}/nodes`;

export const ROUTE_URI = `${NODES_URI}/route`;
export const NODES_CITY_URI = `${NODES_URI}/city`;
const NODES_CITY_SEARCH_URI = `${NODES_CITY_URI}/search`;
const NODES_UPDATE_URI = `${NODES_URI}/update`;

export const GetCitySearchUrl = (citySearch: string) =>
    `${NODES_CITY_SEARCH_URI}?search=${encodeURIComponent(citySearch)}`;

export const GetCityNodesUrl = (cityId: number) =>
    `${NODES_CITY_URI}/${cityId}`;

export const GetAthleteNodesUpdateUrl = (athleteId: number) =>
    `${NODES_UPDATE_URI}/${athleteId}`;

export const GetAthleteCityNodesUpdateUrl = (athleteId: number, cityId: number) =>
    `${GetAthleteNodesUpdateUrl(athleteId)}/${cityId}`;

const S3_NODES_KEY = `${S3_ROOT}/nodes`;

export const GetJobUrl = (id: string, type: string) =>
    `${S3_NODES_KEY}/jobs/${type}/${id}`;

export const GetAthleteCitiesDataUrl = (athleteId: number) =>
    `${S3_NODES_KEY}/${athleteId}/cities.json`;

export const GetAthleteActivitiesUrl = (athleteId: number) =>
    `${S3_NODES_KEY}/${athleteId}/activities.json`;

export const GetAthleteCityIdPath = (athleteId: number, cityId: number) =>
    `${S3_NODES_KEY}/${athleteId}/${cityId}`;

export const GetAthleteCityCoverageUrl = (athleteId: number, cityId: number) =>
    `${GetAthleteCityIdPath(athleteId, cityId)}/coverage.json`

export const GetAthleteCityWayMapUrl = (athleteId: number, cityId: number) =>
    `${GetAthleteCityIdPath(athleteId, cityId)}/cityWayMap.json`;

export const GetAthleteCityTileUrl = (athleteId: number, cityId: number) =>
    `${GetAthleteCityIdPath(athleteId, cityId)}/tiles/tile.json`;

export const GetAthleteCityPathsUrl = (athleteId: number, cityId: number) =>
    `${GetAthleteCityIdPath(athleteId, cityId)}/paths.json`;

export const GetAthleteCityNodesUrl = (athleteId: number, cityId: number) =>
    `${GetAthleteCityIdPath(athleteId, cityId)}/nodes.json`;

export const UNOFFICIAL_GARMIN_URI = `${API_URI}/unofficialgarmin/running`;

export const FetchAndAddToCacheJSON = async (cache: Cache, url: string) => {
    const request = fetch(url, { cache: "reload" });
    const awaitRequest = await request;
    cache.put(url, awaitRequest.clone());
    return awaitRequest;
}

export const PutObjectInCache = async (cache: Cache, url: string, data: any) => {
    cache.put(url, new Response(JSON.stringify(data)));
}

export const DelayForMilliseconds = (ms: number) => new Promise(res => setTimeout(res, ms));

// https://stackoverflow.com/a/58296791
export const waitFor = async (test: () => Promise<string|undefined>, timeout_ms = 20 * 1000, frequency = 5000) => {
    let logPassed = () => console.log('Passed: ', test);
    let logTimedout = () => console.log(`%cTimeout : ${test}`, 'color:#cc2900');
    let last = Date.now();
    let logWaiting = () => { 
        if(Date.now() - last > 1000) {
            last = Date.now();
            console.log(`%cwaiting for: ${test}`, 'color:#809fff'); 
        }
    }

    let endTime = Date.now() + timeout_ms;
    let result = await test();
    while (result === undefined || result === null || result.length === 0) {
        if (Date.now() > endTime) {
            logTimedout();
            return undefined;
        }
        logWaiting();
        await DelayForMilliseconds(frequency);
        result = await test();
    }
    logPassed();
    return result;
}

export const GetFileContentsOrUndefeind = async (url: string) => {
    try {
        var fetchResult = await fetch (url);
        if (!fetchResult.ok) {
            return undefined;
        }
        return await fetchResult.text();
    } catch (ex) {
        return undefined;
    }
}

export interface LambdaInvokeResponseMetadata {
    requestId: string;
}

export const GetFromCacheJSON = async (cache: Cache, url: string, delay?: number) => {
    const maybeMatch = await cache.match(url);
    // console.log(`GetFromCacheJSON() maybeMatch:`, maybeMatch, `(ok: ${maybeMatch && maybeMatch.ok}) for url ${url}`)
    if (maybeMatch && maybeMatch.ok) {
        // console.log('match was ok')
        return (await maybeMatch.json());
    } else {
        // console.log('match was not ok');
        const awaitRequest = await FetchAndAddToCacheJSON(cache, url);
        const awaitRequestJson = awaitRequest.json();
        const awaitAwaitRequestJson = await awaitRequestJson;
        if (delay && awaitRequest.headers.has('uncached')) {
            await DelayForMilliseconds(delay);
        }
        return awaitAwaitRequestJson;
    }
}

export const GetCacheMatch = async (cache: Cache, url: string) => {
    const maybeMatch = await cache.match(url);
    return (maybeMatch !== undefined) && maybeMatch.ok;
}

export const GetFromCacheOrEmpty = async (cache: Cache, url: string, empty: any) => {
    const maybeMatch = await cache.match(url);
    if (maybeMatch && maybeMatch.ok) {
        // console.log('match was ok')
        return (await maybeMatch.json());
    } else {
        await PutObjectInCache(cache, url, empty);
        return empty;
    }
}

export const GetMultipleFromCacheJSON = async (cache: Cache, urls: string[]) => {
    return Promise.all(urls.map(url => {
        return GetFromCacheJSON(cache, url);
    }));
}

const zeroPad = (number: number) => `${(number < 10) ? '0' : ''}${number}`;

// https://stackoverflow.com/a/35470003
export const ToISOStringLocal = (date: Date) => `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(date.getDate())}`;

export interface RunningDataView {
    name: string;
    dataView: React.ElementType<DataViewPropsBase>;
    color: string;
    icon: IconDefinition;
}

export const DATA_VIEWS: { [id: string] : RunningDataView } = {
    DurationDistance: {
        name: 'Duration v. Distance',
        color: 'hsla(1, 37%, 55%, 1)',
        icon: faSignal,
        dataView: DurationDistance,
    },
    TimeCircles: {
        name: 'Time Circles',
        color: '#FB0',
        icon: faCircleNotch,
        dataView: Circles,
    },
    Paths: {
        name: 'Paths',
        color: '#0BF',
        icon: faRoute,
        dataView: Paths,
    },
    Cumulative: {
        name: 'Cumulative',
        color: '#B5C',
        icon: faChartLine,
        dataView: Cumulative,
    },
    Calendar: {
        name: 'Calendar',
        color: '#F80',
        icon: faCalendar,
        dataView: Calendar,
    },
    Timeline: {
        name: 'Timeline',
        color: '#CCF',
        icon: faChartArea,
        dataView: Timeline,
    },
    HeatmapV3: {
        name: 'Heatmap',
        color: '#952',
        icon: faFireAlt,
        dataView: Heatmap,
    },
    Tiles: {
        name: 'Tiles',
        color: '#529',
        icon: faThLarge,
        dataView: Tiles,
    },
    List: {
        name: 'List',
        color: '#295',
        icon: faListAlt,
        dataView: List,
    },
    NodesProgress: {
        name: 'Nodes Progress',
        color: '#925',
        icon: faDotCircle,
        dataView: NodesProgress,
    }
}

export const ParseRawActivityData = (activity: RawActivityData): ActivityData => {
    const decodedPoly: [number,number][] = polyline.decode(activity.polyline || '').map(a => [a[1], a[0]]);
    const extentX = d3.extent(decodedPoly.map(a => a[0]));
    const extentY = d3.extent(decodedPoly.map(a => a[1]));

    const minX = extentX[0] || 0;
    const maxX = extentX[1] || 0;
    const minY = extentY[0] || 0;
    const maxY = extentY[1] || 0;

    const diffX = maxX - minX;
    const diffY = maxY - minY;

    const fixedArray = decodedPoly.length > 0
        ? decodedPoly.map(a => [(a[0] - minX), (maxY - a[1])])
        : [[0,0]];

    return {
        ...activity,
        decodedPoly,
        minX, maxX,
        minY, maxY,
        diffX, diffY,
        fixedArray,
        date: new Date(activity.date.replace("Z", "")),
        start: new Date(activity.start.replace("Z", "")),
    }
}

export const GetRemainingNodeCountForWay = (totalNodes: number, completedNodes: number) =>
    Math.max(RequiredNodesForWay(totalNodes) - completedNodes, 0);

export const RequiredNodesForWay = (wayNodes: number) => wayNodes < 10
    ? wayNodes
    : Math.ceil(wayNodes * 0.9);

/*
export const MapReduceOSMWays = (wayNames: string[], ways: number[][], processedNodes: Node[]) => {
    return wayNames.reduce((
        accumulator: {[key: string]: CityWay },
        wayName: string,
        index: number,
    ) => {
        if (!accumulator.hasOwnProperty(wayName)) {
            accumulator[wayName] = {
                ids: [],
                nodes: [],
                completed: 0,
                complete: false,
                completePercent: 0,
                processedNodes: [],
            };
        }
        accumulator[wayName].ids.push(index);
        accumulator[wayName].nodes.push(ways[index]);
        const nodes = Array.from(new Set(
            accumulator[wayName].nodes.reduce((accumulator, way) => {
                Array.prototype.push.apply(accumulator, way);
                return accumulator;
            }, [])));
        const wayNodeCount = nodes.length;
        const completedNodes = nodes.filter(node => {
            return processedNodes[node].activityId;
        });
        const completedCount = completedNodes.length;
        const complete = (wayNodeCount < 10)
            ? (completedCount === wayNodeCount)
            : ((completedCount / wayNodeCount) >= 0.9);
        
        accumulator[wayName].completed = completedCount;
        accumulator[wayName].complete = complete;
        accumulator[wayName].completePercent = completedCount / wayNodeCount;
        accumulator[wayName].processedNodes = nodes.map(node => processedNodes[node]);

        return accumulator;
    }, {});
}*/

export const ProcessNodes = (
    cityNodes: [[number, number], number[]][],
    bracketedPathCoordinates: CoordinateBracket,
) => {
    const activityToNodesFoundMap: { [activity: number] : CityNode[] } = {};
    const processedNodes = cityNodes.map((node, id) => {
        const [coordinates, ways] = node;
        const [latB, lonB] = coordinates;

        const relevantNodes: PathCoordinate[] = FindRelevantNodesForLatitudeLongitudePoint(
            coordinates[0],
            coordinates[1],
            bracketedPathCoordinates
        ).filter(coordinate => {
                return MeterDistanceBetweenLatitudeLongitude(
                    {
                        lat: coordinate.latitude,
                        lon: coordinate.longitude
                    },
                    {
                        lat: latB,
                        lon: lonB
                    }) < 25;
            }).sort((a, b) => a.activityId - b.activityId );

        const activityId = relevantNodes[0]?.activityId;

        const processedNode = {
            ways,
            id,
            activityId,
            coordinates,
        };

        if (activityId) {
            if (!activityToNodesFoundMap.hasOwnProperty(activityId)) {
                activityToNodesFoundMap[activityId] = [];
            }
            activityToNodesFoundMap[activityId].push(processedNode);
        }

        return processedNode;
    });
    return {
        processedNodes,
        activityToNodesFoundMap,
    };
}

const usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue);
    useEffect(() => { ref.current = value; });
    return ref.current;
};

export const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames = []) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum: any, dependency: any, index: number) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency
          }
        };
      }
  
      return accum;
    }, {});
  
    if (Object.keys(changedDeps).length) {
      console.log('[use-effect-debugger] ', changedDeps);
    }

    useEffect(effectHook, [...dependencies, effectHook]);
};