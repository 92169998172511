import StackedBarChart from "src/lib/d3/StackedBarChart";

interface SavingsChartProps {
	data: Map<Date, Map<string,number>>;
}

const SavingsChart: React.FC<SavingsChartProps> = ({ data }) => {
	const dataPoints: [Date,number,number][] = Array.from(data.entries()).map(([date, entries]) => {
		const income = entries.get('Income');
		const expendituresFiltered = Array.from(entries.entries())
			.filter(([category,]) => category !== 'Income' &&
				category !== 'Investments' &&
				category !== 'Transfer' &&
				category !== 'Taxes');
		const expenditure = expendituresFiltered
			.reduce((accumulator, [,value]) => {
				return accumulator + value;
			}, 0);
		const savings = ((income || 0) + expenditure);
		const savingsRate = (income ? (savings / income) : 0) * 100;
		return [date, savingsRate, expenditure];
	});
	// const average = dataPoints.reduce((a, [,value]) => a + value, 0) / dataPoints.length;
	const stackedData = new Map();
	for (var dataPoint of dataPoints) {
		const dataPointMap = new Map();
		dataPointMap.set('Savings Rate', dataPoint[1]);
		stackedData.set(dataPoint[0], dataPointMap);
	}
	return (
		<div style={{ width: '100%', height: '100%'}}>
			<StackedBarChart
				data={stackedData}
				stackClicked={() => {}}
				yAxisFormat={(a: any) => `${a.toFixed(0)}%`}
				colorMap={new Map([["Savings Rate", '#7496ED']])}
				domainPadding={10}
			/>
		</div>
	)
}

export default SavingsChart;