import { ReactElement, useEffect, useRef, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form"
import Diff from 'diff';

enum SEPARATORS {
	TAB = 'tab',
	PIPE = 'pipe',
	COMMA = 'comma',
}

const SEPARATORS_MAP: {[key in SEPARATORS]: string} = {
	tab: '\t',
	pipe: '|',
	comma: ',',
};

const SANITIZED_SEPARATOR = ` ${SEPARATORS_MAP.pipe} `;

const REPLACEMENTS = [
	[' TWP\\. ', ' TOWNSHIP '],
	[' TWP ', ' TOWNSHIP '],
	[' ST \\|',' STREET |'],
	[' ST\\. \\|', ' STREET |'],
	[' RD ',' ROAD '],
	[' RD. ', 'ROAD '],
	[' BLDG ',' BUILDING '],
	['^ST ','SAINT '],
	['^ST\\. ','SAINT '],
	['\\| ST\\. ','| SAINT '],
	['\\| ST ','| SAINT '],
	[' DR ',' DRIVE '],
	[' RTE ',' ROUTE '],
	[' WD ', ' WARD '],
	[' RT ', ' ROUTE '],
	[' DEPT ', ' DEPARTMENT '],
	[' PKWY ', ' PARKWAY '],
	[' BORO ', ' BOROUGH '],
	[' AVE ', ' AVENUE '],
	[' AVE. ', ' AVENUE '],
	[' LN ', ' LANE '],
	[' LN. ', ' LANE '],
	[' PK ', ' PIKE '],
	[' PK. ', ' PIKE '],
	[' N. ', ' N '],
	[' E. ', ' E '],
	[' S. ', ' S '],
	[' W. ', ' W '],
	[' N ', ' NORTH '],
	[' E ', ' EAST '],
	[' S ', ' SOUTH '],
	[' W ', ' WEST '],
	[' PL ', ' PLACE '],
	[' PL. ', ' PLACE '],

]

const BACKGROUND_COLORS = {
	'ADDED': '#eafbf2',
	'REMOVED': '#fee7e7',
}

const COLORS = {
	'ADDED': '#064',
	'REMOVED': '#ad1f1f',
}

const ADDED_STYLE = {
	borderRadius: '5px',
	padding: '5px',
	color: COLORS.ADDED,
	backgroundColor: BACKGROUND_COLORS.ADDED,
}

const ADDED_ROW_STYLE = {
	backgroundColor: COLORS.ADDED,
}

const REMOVED_ROW_STYLE = {
	backgroundColor: COLORS.REMOVED,
}

const REMOVED_STYLE = {
	borderRadius: '5px',
	padding: '5px',
	textDecoration: 'line-through',
	color: COLORS.REMOVED,
	backgroundColor: BACKGROUND_COLORS.REMOVED,
}

const sanitize = (input: string, separator: SEPARATORS) => {
	let sanitized = input.toUpperCase();
	// console.log(sanitized);
	if (separator !== SEPARATORS.PIPE) {
		const literalSeparator = SEPARATORS_MAP[separator];
		const doubleSeparatorRegex = new RegExp(`${literalSeparator}${literalSeparator}`, 'g');
		sanitized = sanitized.replaceAll(doubleSeparatorRegex, SANITIZED_SEPARATOR);
		const separatorRegex = new RegExp(literalSeparator, 'g');
		sanitized = sanitized.replaceAll(separatorRegex, SANITIZED_SEPARATOR);
	}
	for (var i = 0; i < REPLACEMENTS.length; i++) {
		const regexString = REPLACEMENTS[i][0];
		const replacement = REPLACEMENTS[i][1];
		const regex = new RegExp(regexString, 'g');
		// console.log(regexString, replacement, regex);
		sanitized = sanitized.replaceAll(regex, replacement);
		// console.log(sanitized)
	}
	const sanitizedLines = sanitized.split('\n');
	for (var j = 0; j < sanitizedLines.length; j++) {
		const sanitizedLine = sanitizedLines[j];
		if (sanitizedLine.split('|').length !== 5) {
			sanitizedLines[j] = sanitizedLines[j] + ' | ';
		}
	}
	return sanitizedLines.join('\n');
}

const SVDiff: React.FC = () => {
	const [hideUnchangedRows, setHideUnchangedRows] = useState(false);
	const [unchangedRowCount, setUnchangedRowCount] = useState(0);
	const [leftSanitized, setLeftSanitized] = useState('');
	const [rightSanitized, setRightSanitized] = useState('');
	const [diffLines, setDiffLines] = useState<Diff.Change[][]>([]);

	useEffect(() => {
		const firstDiffs = Diff.diffWordsWithSpace(leftSanitized, rightSanitized);
		const lines: Diff.Change[][] = [];
		let line: Diff.Change[] = [];
		for (var i = 0; i < firstDiffs.length; i++) {
			const diff = firstDiffs[i];
			const parts = diff.value.split('\n');
			// console.log('looking at diff:', diff)
			if (diff.value.startsWith('\n') && parts.length === 2) {
				lines.push(line);
				line = [];
			} else if (diff.value.endsWith('\n') && parts.length === 2) {
				line.push(diff);
				lines.push(line);
				line = [];
				continue;
			} else if (parts.length !== 1) {
				const parts = diff.value.split('\n');
				// console.log('got parts:', parts);
				for (var j = 0; j < parts.length - 1; j++) {
					line.push({
						value: parts[j],
						added: diff.added,
						removed: diff.removed,
					});
					lines.push(line);
					line = [];	
				}
				line.push({
					value: parts[parts.length - 1],
					added: diff.added,
					removed: diff.removed,
				});
				continue;
			}
			if (diff.value !== '\n') {
				line.push(diff);
			}
		}
		lines.push(line);
		setDiffLines(lines);
		setUnchangedRowCount(lines.filter(line => line.length === 1 && !line[0].added && !line[0].removed).length);
	}, [leftSanitized, rightSanitized]);

	const GetDiffRowsFromDiffLines = (diffLines: Diff.Change[][], hideUnchangedLines: boolean) => {
		let leftIndex = 0;
		let rightIndex = 0;
		const result: ReactElement[] = [];
		// console.log(diffLines);
		for (var i = 0; i < diffLines.length; i++) {
			const diffLine = diffLines[i];
			const key = `${i}-diffLineRow`;
			const rowKey = `${i}-diffLine`;
			let lineLeftIndex: number|undefined = leftIndex;
			let lineRightIndex: number|undefined = rightIndex;
			leftIndex += 1;
			rightIndex += 1;
			if (diffLine.length === 1) {
				const onlyDiffLine = diffLine[0];
				if (onlyDiffLine.added) {
					leftIndex -= 1;
					lineLeftIndex = undefined;
				} else if (onlyDiffLine.removed) {
					rightIndex -= 1;
					lineRightIndex = undefined;
				} else if (hideUnchangedLines) {
					continue;
				}
			}
			result.push(<DiffLineRow
				key={key}
				rowKey={rowKey}
				diffLine={diffLine}
				leftIndex={lineLeftIndex}
				rightIndex={lineRightIndex}
			/>);
		}
		return result;
	}

	return (
		<div
			style={{
				margin: '0 auto',
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<h4
				style={{
					margin: '10px 10px 0px 10px',
					flex: '0 1 auto',
				}}
			>
				Separated-Value Diff Tool
				<span
					style={{
						float: 'right',
						fontSize: '1rem',
					}}
				>
					<Form.Switch
						label={`${!hideUnchangedRows ? 'Showing': 'Hiding'} ${unchangedRowCount} Unchanged Rows`}
						checked={hideUnchangedRows}
						onChange={() => { setHideUnchangedRows(!hideUnchangedRows) }}
						id="hideUnchangedLines"
						inline
					/>
				</span>
			</h4>
			<div
				style={{
					display: 'flex',
					flex: '0 1 auto',
				}}
			>
				<DiffSide
					header="Left"
					setSanitizedCallback={setLeftSanitized}
				/>
				<DiffSide
					header="Right"
					setSanitizedCallback={setRightSanitized}
				/>
			</div>
			<div
				style={{
					flex: '1 1 auto',
					overflow: 'scroll',
					padding: '5px',
				}}
			>
				<Table
					bordered
					hover
					size="sm"
					style={{ fontSize: '.65rem', marginBottom: '0px' }}
				>
					<tbody>
						{GetDiffRowsFromDiffLines(diffLines, hideUnchangedRows)}
					</tbody>
				</Table>
			</div>
		</div>
	);
}

export default SVDiff;

const GetLineNumbers = (text: string) => {
	return Array.from({ length: text.split('\n').length }, (_, i) => {
		return `${i+1}`
	}).join('\n');
}

interface DiffSideProps {
	header: string;
	setSanitizedCallback: (value: string) => void;
}

const diffSideStoragePrefix = (header: string) => `svdiff-${header}`;
const diffSideSeparatorItem = (header: string) => `${diffSideStoragePrefix(header)}-separator`;
const diffSideTextItem = (header: string) => `${diffSideStoragePrefix(header)}-text`;

const DiffSide: React.FC<DiffSideProps> = ({ header, setSanitizedCallback }) => {
	const SEPARATOR_ITEM = diffSideSeparatorItem(header);
	const TEXT_ITEM = diffSideTextItem(header);
	
	const [separator, setSeparator] = useState<SEPARATORS>((localStorage.getItem(SEPARATOR_ITEM) as SEPARATORS) || SEPARATORS.TAB);
	const [sanitized, setSanitized] = useState('');
	const [text, setText] = useState(localStorage.getItem(TEXT_ITEM) || header === "Left" ? TEST_LEFT : TEST_RIGHT);

	useEffect(() => {
		const sanitizedText = sanitize(text, separator);
		setSanitized(sanitizedText);
		setSanitizedCallback(sanitizedText);
	}, [text, separator, setSanitizedCallback]);

	return (
		<div style={{ flex: '1 1 auto', margin: '10px' }}>
			<Card>
				<Card.Header as="h5">
					{header}
					<div style={{ float: 'right' }}>
						<Form inline>
							<small style={{ marginBottom: '0' }}>Separator:</small>
							<Form.Control
								as="select"
								size="sm"
								style={{ width: 'auto', marginLeft: '3px' }}
								onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
									const separatorValue = event.currentTarget.value
									setSeparator(separatorValue as SEPARATORS);
									localStorage.setItem(SEPARATOR_ITEM, separatorValue);
								}}
								value={separator}
							>
								{Object.keys(SEPARATORS_MAP).map(separatorName =>
									<option
										value={separatorName}
										key={separatorName}
									>
										{separatorName}
									</option>
								)}
							</Form.Control>
						</Form>
					</div>
				</Card.Header>
				<Card.Body style={{ padding: '0' }}>
					<TextAreaWithLineNumbers
						text={text}
						onTextChange={(updatedText: string) => {
							setText(updatedText);
							localStorage.setItem(TEXT_ITEM, updatedText);
						}}
						showWhiteSpace={true}
					/>
				</Card.Body>
				<Card.Footer>
					<details>
						<summary>Click here to show sanitized input</summary>
						<TextAreaWithLineNumbers
							text={sanitized}
							readOnly={true}
							showWhiteSpace={true}
						/>
					</details>
				</Card.Footer>
			</Card>
		</div>
	);
}

interface TextAreaWithLineNumbersProps {
	text: string;
	readOnly?: boolean;
	onTextChange?: (updatedText: string) => void;
	showWhiteSpace?: boolean;
}

const TextAreaWithLineNumbers: React.FC<TextAreaWithLineNumbersProps> = ({
	text,
	onTextChange = () => {},
	readOnly = false,
	showWhiteSpace = false,
}) => {
	const [textAreaHeight, setTextAreaHeight] = useState(0);

	const resizeRef = useRef<ResizeObserver>();
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const lineNumbersRef = useRef<HTMLPreElement>(null);

	useEffect(() => {
		if (!resizeRef.current) {
            resizeRef.current = new ResizeObserver(() => {
				if (textAreaRef.current) {
					setTextAreaHeight(textAreaRef.current.getBoundingClientRect().height);
				}
			});
        }

		const { current: currentTextAreaRef } = textAreaRef;
        if (currentTextAreaRef) {
            resizeRef.current.observe(currentTextAreaRef);
        }
		
		return () => {
			if (resizeRef.current && currentTextAreaRef) {
                resizeRef.current?.unobserve(currentTextAreaRef);
            }
		}
	}, []);

	return (
		<div
			style={{
				fontFamily: 'monospace',
				display: 'flex',
			}}
		>
			<pre
				style={{
					overflow: 'hidden',
					padding: '.375rem .25rem',
					border: '1px solid #ced4da',
					borderRight: '0px',
					textAlign: 'right',
					fontSize: '1rem',
					lineHeight: '1.5',
					marginBottom: '0',
					height: `${textAreaHeight}px`,
				}}
				ref={lineNumbersRef}
			>
				{GetLineNumbers(text)}
			</pre>
			<Form.Control
				as="textarea"
				ref={textAreaRef}
				value={showWhiteSpace
					? text.replaceAll(' ', '␣').replaceAll('\t','→')
					: text}
				readOnly={readOnly}
				style={{
					whiteSpace: 'pre',
					borderTopLeftRadius: '0',
					borderBottomLeftRadius: '0',
					height: '100px',
				}}
				onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
					const textValue = event.currentTarget.value;
					onTextChange(showWhiteSpace
						? textValue.replaceAll('␣', ' ').replaceAll('→','\t')
						: textValue);
				}}
				onScroll={(event: React.UIEvent<HTMLTextAreaElement, UIEvent>) => {
					if (lineNumbersRef.current) {
						lineNumbersRef.current.scrollTop = event.currentTarget.scrollTop;
					}
				}}
			/>
		</div>
	);
}

const DiffLineRowData = (diffLine: Diff.Change[], rowKey: string) => {
	const data: ReactElement<any,any>[] = [];
	let carry = [];
	// console.log(diffLine);
	for (var i = 0; i < diffLine.length; i++) {
		const linePart = diffLine[i];
		const linePartStyle = linePart.added
			? ADDED_STYLE
			: linePart.removed
				? REMOVED_STYLE
				: {};
		// console.log('PARSING: [', linePart.value, ']')
		const valueParts = linePart.value.split('|');
		if (valueParts.length > 1) {
			for (var j = 0; j < valueParts.length - 1; j++) {
				data.push(<td key={`${rowKey}-${i}-${j}`}>
					{carry}<span
						style={linePartStyle}
					>
						{valueParts[j]}
					</span>
				</td>);
				carry = [];
			}
		}
		carry.push(<span
			style={linePartStyle}
			key={`${rowKey}-${i+1}-prefix`}
		>
			{valueParts[valueParts.length - 1]}
		</span>);
	}
	if (carry.length !== 0) {
		data.push(<td key={`${rowKey}-${i+1}-leftover`}>
			{carry}
		</td>);
	}
	return data;
}

interface DiffLineRowProps {
	diffLine: Diff.Change[];
	rowKey: string;
	leftIndex?: number;
	rightIndex?: number;
}

const DiffLineRow: React.FC<DiffLineRowProps> = ({ diffLine, rowKey, leftIndex, rightIndex }) => {
	const data = diffLine.length === 1
		? diffLine[0].value.split(' | ').map((s, j) =>
			<td
				key={`${rowKey}-${j}`}
			>
				<span
					style={diffLine[0].added
						? ADDED_STYLE
						: diffLine[0].removed
							? REMOVED_STYLE
							: {}}
				>
					{s}
				</span>
			</td>
		)
		: DiffLineRowData(diffLine, rowKey);

	return (
		<tr
			key={rowKey}
			style={diffLine.length === 1
				? diffLine[0].added
					? ADDED_ROW_STYLE
					: diffLine[0].removed
						? REMOVED_ROW_STYLE
						: {}
				: {}}
		>
			<td>{leftIndex !== undefined && leftIndex + 1}</td>
			<td>{rightIndex !== undefined && rightIndex + 1}</td>
			{data}
		</tr>
	)
}

const TEST_LEFT = `Armagh - East	SIGLERVILLE BASEBALL ORGANIZATION		1122 LOCKE MILLS RD	MILROY	17063
Armagh - West	MILROY HOSE CO. #1		190 COLLEGE AVE	MILROY	17063
Bratton	LONGFELLOW PLAYGROUND BUILDING		2216 SR 103 N	LEWISTOWN	17044
Brown - Church Hill	KISH VALLEY GRACE BRETHREN CHURCH		99 TAYLOR DR	REEDSVILLE	17084
Brown - Reedsville/Big Valley	REEDSVILLE FIRE CO. BANQUET HALL		16 FIREHOUSE BLVD	REEDSVILLE	17084
Burnham Boro	WALNUT STREET UNITED METHODIST CHURCH		203 N WALNUT ST	BURNHAM	17009
Decatur - East	DECATUR TOWNSHIP BUILDING		1900 SNOOK RD	MC CLURE	17841
Decatur - West	DECATUR FIREMEN'S COOKHOUSE		4379 US HWY 522 N	LEWISTOWN	17044
Derry - East	LEWISTOWN SENIOR CITIZEN CENTER		515 KNEPP AVE	LEWISTOWN	17044
Derry - North	MAITLAND CHURCH OF THE BRETHREN		315 CROSSOVER DR	LEWISTOWN	17044
Derry - South	RHODES MEMORIAL METHODIST CHURCH		500 HIGHLAND AVE	LEWISTOWN	17044
Derry - West	DERRY TOWNSHIP SENIOR CENTER		501 SIXTH ST	YEAGERTOWN	17099
Granville - East	GRANVILLE TOWNSHIP BUILDING		100 HELEN ST	LEWISTOWN	17044
Granville - West	BODY & SOUL COMMUNITY CENTER		205 CHESTNUT RIDGE RD	MCVEYTOWN	17051
Juniata Terrace	JUNIATA TERRACE BOROUGH BUILDING		80 HUDSON AVE	LEWISTOWN	17044
Kistler Boro	NEWTON-WAYNE BANQUET HALL		9 MARKET ST	NEWTON HAMILTON	17075
Lewistown Central	MIFFLIN COUNTY ANNEX BUILDING		101 W THIRD ST	LEWISTOWN	17044
Lewistown North	GRACE UNITED METHODIST CHURCH		101 LOGAN ST	LEWISTOWN	17044
Lewistown South	ASHER'S CHOCOLATES		19 E SUSQUEHANNA AVE	LEWISTOWN	17044
Lewistown West	EVANGEL BAPTIST CHURCH		375 W FIFTH ST	LEWISTOWN	17044
McVeytown Boro	MCVEYTOWN PRESBYTERIAN CHURCH		5 N QUEEN ST	MCVEYTOWN	17051
Menno	MENNO TOWNSHIP BUILDING		39 WATER ST	ALLENSVILLE	17002
Newton Hamilton	NEWTOWN WAYNE BANQUET HALL		9 MARKET ST	NEWTON HAMILTON	17075
Oliver	OLIVER TOWNSHIP BUILDING		4670 US HWY 522 S	MCVEYTOWN	17051
Union	FORMER UNION ELEMENTARY SCHOOL BUILDING		95 N PENN ST	BELLEVILLE	17004
Wayne	TUSCARORA INTERMEDIATE UNIT 11 (TIU)		2527 US HIGHWAY 522 S	MCVEYTOWN	17051`;

const TEST_RIGHT = `Armagh - East	Siglerville Baseball Organization	1122 Locke Mills Rd.	Milroy
Armagh - West	Milroy Hose Co. #1	190 College Ave.	Milroy
Bratton	Longfellow Playground Building	2216 SR 103 N.	Lewistown
Brown - Church Hill	Kish Valley Grace Brethren Church	99 Taylor Dr.	Reedsville
Brown - Reedsville/Big Valley	Reedsville Fire Co. Banquet Hall	16 Firehouse Blvd.	Reedsville
Burnham	Burnham United Methodist	203 N. Walnut St. Burnham
Decatur - East	Decatur Township Building	1900 Snook Rd.	McClure
Decatur - West	Decatur Fireman's Cookhouse	4379 US-522	Lewistown
Derry - East	Lewistown Senior Citizen Center formerly Central Novelty Building	515 Knepp Ave.	Lewistown
Derry - North	Maitland Church of the Brethren	315 Crossover Dr.	Lewistown
Derry - South	Rhodes Memorial Methodist Church	500 Highland Ave.	Lewistown
Derry - West	Derry Township Senior Center	15 W. Mill St.	Yeagertown
Granville - East	Granville Township Building	100 Helen St.	Lewistown
Granville- West	Body and Soul Community Center	205 Chestnut Ridge Rd.	McVeytown
Juniata Terrace	Juniata Terrace Borough Building	80 Hudson Ave.	Lewistown
Kistler	Newton-Wayne Banquet Hall	9 Market St.	Newton Hamilton
Lewistown Central	Mifflin County Annex Building	101 W. Third St.	Lewistown
Lewistown North	Grace United Methodist Church	101 Logan St.	Lewistown
Lewistown South	Asher's Chocolates	19 E. Susquehanna Ave.	Lewistown
Lewistown West	Evangel Baptish Church	375 W. Fifth St.	Lewistown
McVeytown Borough	McVeytown Presbyterian Church	5 N. Queen St.	McVeytown
Menno	Menno Township Building	39 Water St.	Allensville
Newton Hamilton	Newton-Wayne Banquet Hall	9 Market St.	Newton Hamilton
Oliver	Oliver Township Bldg.	4670 US HWY 522 S.	McVeytown
Union	Former Union Elementary Building	95 N. Penn St.	Rear	Belleville
Wayne	Country Crossroads Community Center	3055 Ferguson Valley Rd	McVeytown`;