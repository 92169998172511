import { curveStepAfter } from "d3";
import SimplyGraph, { GraphLine } from "../../lib/d3/SimplyGraph";
import { formatter } from "../../lib/d3/common";

export interface AccountsCategories {
	liquid: string[];
	other: string[];
	liabilities: string[];
}

interface ChartsProps {
    balances: Map<Date,Map<string,number>>;
	accountsCategories: AccountsCategories;
}

const LIQUID_TOTAL = 'Liquid Total';
const TOTAL = 'Total';

const Charts: React.FC<ChartsProps> = ({ balances, accountsCategories }) => {
	const OTHER_ACCOUNTS = accountsCategories.other.concat(TOTAL, LIQUID_TOTAL);
	const ASSET_ACCOUNTS = accountsCategories.liquid.concat(OTHER_ACCOUNTS);
	const graphLinesMap: {[key: string]: GraphLine<[Date,number]>} = {};
	graphLinesMap[LIQUID_TOTAL] = { name: LIQUID_TOTAL, dataPoints: []};
	graphLinesMap[TOTAL] = { name: TOTAL, dataPoints: []};
	balances.forEach((dateBalances, date) => {
		let liquidTotalBalance = 0;
		let totalBalance = 0;
		dateBalances.forEach((balance, account) => {
			if (Object.keys(graphLinesMap).indexOf(account) < 0) {
				graphLinesMap[account] = {
					dataPoints: [],
					name: account,
				}
			}
			graphLinesMap[account].dataPoints.push([date,+balance.toFixed(2)]);
			if (accountsCategories.liquid.indexOf(account) >= 0) {
				totalBalance = +(balance + totalBalance).toFixed(2);
				liquidTotalBalance = +(balance + liquidTotalBalance).toFixed(2);
			} else if (OTHER_ACCOUNTS.indexOf(account) >= 0) {
				totalBalance = +(balance + totalBalance).toFixed(2);
			}
		});
		graphLinesMap[TOTAL].dataPoints.push([date, totalBalance]);
		graphLinesMap[LIQUID_TOTAL].dataPoints.push([date, liquidTotalBalance]);
	});
	const assetGraphLines = Object.keys(graphLinesMap)
		.filter(a => ASSET_ACCOUNTS.indexOf(a) !== -1)
		.map(k => graphLinesMap[k]);
	const liabilitiesGraphLines = Object.keys(graphLinesMap)
		.filter(a => accountsCategories.liabilities.indexOf(a) !== -1)
		.map(k => graphLinesMap[k]);
	return (
		<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
			<div style={{ flex: '1 0 auto'}}>
				<SimplyGraph
					graphLines={assetGraphLines}
					yAccessor="1"
					xAccessor="0"
					yAxisFormat={(a: any) => formatter.format(a)}
					margins={{ top: 50, left: 80, bottom: 40, right: 100 }}
					lineCurve={curveStepAfter}
					focusStroke="#111"
				/>
			</div>
			<div style={{ flex: '1 0 auto'}}>
				<SimplyGraph
					graphLines={liabilitiesGraphLines}
					yAccessor="1"
					xAccessor="0"
					yAxisFormat={(a: any) => formatter.format(a)}
					margins={{ top: 50, left: 80, bottom: 40, right: 100 }}
					lineCurve={curveStepAfter}
					focusStroke="#111"
				/>
			</div>
		</div>
	);
}

export default Charts;