import { FormControl, OverlayTrigger, Popover, PopoverContent, PopoverTitle, Table } from "react-bootstrap";
import { Transaction } from "./types";
import { CSSProperties, useCallback, useMemo, useState } from "react";
import { formatter } from "../../lib/d3/common";
import { debounce } from "src/lib/Utils/Debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

interface TransactionTableProps {
    transactions: Transaction[];
}

const headerStyle: CSSProperties = {
    position: 'sticky',
    top: '0px',
    backgroundColor: '#FFF'
}

const columns: Map<string,[string,((i: any) => string)|undefined,string|undefined]> = new Map([
    ['Date', ['date', (d: Date) => {
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const date = d.getDate();
        return `${year}-${month < 10 ? '0' : ''}${month}-${date < 10 ? '0' : ''}${date}`
    },undefined]],
    ['Value', ['value', (i: number) => formatter.format(i), undefined]],
    ['Account', ['account', undefined, undefined]],
    ['Category', ['category', undefined, undefined]],
    ['Description', ['description', undefined, 'originalDescription']]
]) || new Map();



const TransactionTable: React.FC<TransactionTableProps> = ({ transactions }) => {
    const [sort, setSort] = useState<keyof Transaction>('date');
    const [reverseSort, setReverseSort] = useState(false);
    const [filter, setFilter] = useState<string>();
    
    const setSortAndReverse = useCallback((clickedSort: keyof Transaction) => {
        setSort(clickedSort);
        if (clickedSort !== sort) {
            setReverseSort(false);
        } else {
            setReverseSort(!reverseSort);
        }
    }, [sort, reverseSort]);

    const debounceFilter = useMemo(
        () =>
            debounce(
                (updatedFilter: string) => { setFilter(updatedFilter); },
                300
            ),
        [setFilter]
    );

    const filteredSortedTransactions = transactions.filter((transaction) => {
        return filter !== undefined
            ? transaction.description.indexOf(filter) >= 0
            : true;
    }).sort((a, b) => {
        const aValue = a[sort];
        const bValue = b[sort];
        if (!aValue && !bValue) {
            return 0;
        }
        if (!aValue) {
            return -1;
        }
        if (!bValue) {
            return 1;
        }
        if (aValue === bValue) {
            return 0;
        }
        return reverseSort
            ? (aValue < bValue)
                ? 1
                : -1
            : (aValue > bValue)
                ? 1
                : -1;
    })

    return (
        <>
            <OverlayTrigger
                placement="right"
                trigger={["click", "focus"]}
                
                overlay={
                    <Popover
                        id="popover-basic"
                        style={{ minWidth: '200px' }}
                    >
                        <PopoverTitle as="h3">Search for Transactions</PopoverTitle>
                        <PopoverContent style={{ margin: '0 1rem' }}>
                            <FormControl
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    debounceFilter(event.currentTarget.value);
                                }}
                                placeholder="Search here..."
                            />
                        </PopoverContent>
                    </Popover>}
            >
                {({ ref, ...triggerHandler }) => (
                    <FontAwesomeIcon
                        {...triggerHandler}
                        icon={faSearch}
                        forwardedRef={ref}
                        style={{
                            position: 'fixed',
                            top: '60px',
                            left: '15px'
                        }}
                        size="2x"
                    />
                )}
            </OverlayTrigger>
            <Table
                striped
                bordered
                hover
                size="sm"
                style={{ fontFamily: 'monospace', textAlign: 'right', fontSize: '10px' }}
            >
                <thead>
                    <tr>
                        {Array.from(columns.entries(), ([header, [property,_,___]]) => {
                            return (
                                <th
                                    style={headerStyle}
                                    key={header}
                                    onClick={() => { setSortAndReverse(property)}}
                                >
                                    {sort === property && <FontAwesomeIcon icon={reverseSort ? faSortUp : faSortDown}/>} {header}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {filteredSortedTransactions.map((transaction, index) =>
                        <TransactionRow
                            key={index}
                            id={index}
                            transaction={transaction}
                        />
                    )}
                </tbody>
            </Table>
        </>
    );
}

export default TransactionTable;

interface TransactionRowProps {
    transaction: Transaction;
    id: number;
}

const TransactionRow: React.FC<TransactionRowProps> = ({ transaction, id }) => {
    return (
        <tr
            onClick={() => { console.log(transaction) }}
        >
            {Array.from(columns.entries(), ([key, [property, format, title]]) =>
                <TransactionRowData
                    key={`${id}-${key}`}
                    value={transaction[property]}
                    format={format}
                    title={title ? transaction[title] : undefined}
                />
            )}
        </tr>
    )
}

interface TransactionRowDataProps {
    format?: (i: any) => string;
    value: any;
    title?: any;
}

const TransactionRowData: React.FC<TransactionRowDataProps> = ({ format, value, title }) => {
    const valueString = format
        ? format(value)
        : value;
    return (<td><span title={title}>{valueString}</span></td>);
}