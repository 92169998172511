import { useRef, useState, useEffect, useCallback, forwardRef, createElement } from 'react';
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilter,
    faAngleRight,
    faAngleLeft,
    faBiking,
    faRunning,
    faHiking,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import * as d3 from 'd3';
import "react-datepicker/dist/react-datepicker.css";
import { ActivityData, ActivityStatistics, MileData, RawActivityData } from './types';
import { CACHE_NAME, DATA_VIEWS, GetAthleteActivitiesUrl, GetAthleteNodesUpdateUrl, GetAthleteUrl, GetFileContentsOrUndefeind, GetFromCacheJSON, GetFromCacheOrEmpty, GetJobUrl, LambdaInvokeResponseMetadata, ParseRawActivityData, PutObjectInCache, STRAVA_AUTH_URL, ToISOStringLocal, waitFor } from './Common';
import SignIn from './SignIn';
import { ShrinkingSideMenu } from '../../components/ShrinkingSideMenu';
import toast from 'react-hot-toast';
import { PathProperties } from './HeatmapUtils';
import { useHistory, useLocation } from 'react-router-dom';

interface CustomInputProps {
    startDate: Date;
    endDate: Date;
    onClick?: any;
}

const CustomInput: React.FC<CustomInputProps> = forwardRef(({ startDate, endDate, onClick }: CustomInputProps, _) => {
    return (
        <Button
            block={true}
            onClick={onClick}
        >
            {`${ToISOStringLocal(startDate)} // ${ToISOStringLocal(endDate)}`}
            <FontAwesomeIcon icon={faFilter} style={{ marginLeft: '8px' }}/>
        </Button>
    );
});

const Wrapper: React.FC = () => {
    const cacheRef = useRef<Cache>();
    const [cacheReady, setCacheReady] = useState(false);

    useEffect(() => {
        (async () => {
            cacheRef.current = await window.caches.open(CACHE_NAME);
            setCacheReady(true);
        })();
    }, []);

    return (cacheRef.current && cacheReady)
        ? (
            <Running
                cache={cacheRef.current}
            />
        ) : null;
}

const Running: React.FC<{ cache: Cache }> = ({
    cache
}) => {
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);

    const [loading, setLoading] = useState(true);
    const [menuIsOpen, setMenuIsOpen] = useState(true);
    const [activityStaticstics, setActivityStatistics] = useState<ActivityStatistics>();
    const [data, setData] = useState<ActivityData[]>([]);
    const [filteredData, setFilteredData] = useState<ActivityData[]>([]);
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [startDate, setStartDate] = useState(query.get('end') === null ? new Date() : new Date(query.get('end') as string));
    const [endDate, setEndDate] = useState(query.get('start') === null ? new Date() : new Date(query.get('start') as string));
    const [filter, setFilter] = useState(['Run']);
    const [athleteId] = useState(() => {
        const queryAthleteId = query.get('athlete');
        if (queryAthleteId) {
            localStorage.setItem('athleteId', queryAthleteId);
        }
        const localStorageAthleteId = localStorage.getItem('athleteId');
        if (!localStorageAthleteId) {
            return null;
        }
        return parseInt(localStorageAthleteId);
    });
    const [dataViewName, setDataViewName] = useState(() => {
        const view = query.get('view');
        if (view) {
            setMenuIsOpen(false);
            const querySelectedDataView = Object.keys(DATA_VIEWS).find(dataView => dataView === view);
            if (!querySelectedDataView) {
                toast.error(`Unsupported view in url: ${view}`);
                return null;
            } else {
                return querySelectedDataView;
            }
        }
        return null;
    });

    useEffect(() => {
        const currentFilteredData = data.filter(activity => {
            return (
                (filter.includes(activity.type)) &&
                (activity.date >= startDate) &&
                (activity.date <= endDate)
            );
        });

        const maxDistance = d3.max(currentFilteredData.map(a => a.distance)) || 0;
        const maxDistanceCeiling = Math.ceil(maxDistance);

        const maxDuration = d3.max(currentFilteredData.map(a => a.duration)) || 0;
        const [minPace, maxPace] = d3.extent(currentFilteredData.map(a => a.pace));
        
        const miles: MileData[][] = Array(maxDistanceCeiling).fill([]);
        const allSplits: number[][] = [];

        const splitTimes = Array(maxDistanceCeiling).fill(0);
        const splitSquaredTimes = Array(maxDistanceCeiling).fill(0);
        const splitCounts = Array(maxDistanceCeiling).fill(0);
        const splitAverageTimes = Array(maxDistanceCeiling).fill(0);
        const splitStandardDeviations = Array(maxDistanceCeiling).fill(0);

        let allSplitsTimes = 0;
        let allSplitsSquaredTimes = 0;
        let allSplitsAverageTime = 0;
        let allSplitsAverageSquaredTime = 0;
        let year = 0,
            yearlyDistance = 0,
            totalDistance = 0,
            averagePace = 0;

        for(let i = 0; i < currentFilteredData.length; i++) {
            const activity = currentFilteredData[i];
            const activityYear = (activity.date as Date).getFullYear();
            if (activityYear !== year) {
                year = activityYear;
                yearlyDistance = 0;
            }
            totalDistance += activity.distance;
            yearlyDistance += activity.distance;
            activity.yearlyCumulativeDistance = yearlyDistance;
            activity.totalCumulativeDistance = totalDistance;
            
            // TODO: Why round the pace?
            activity.pace = Math.round(activity.pace);
            averagePace += activity.pace;
            
            // Filtering out short splits here
            activity.splits = activity.splits.filter(s => s.distance > 0.4);

            let runTime = 0;
            activity.splitTimes = 0;
            activity.splitSquaredTimes = 0;
            for(var j = 0; j < activity.splits.length; j++) {
                const split = activity.splits[j];
                runTime += split.time;
                split.totalTime = runTime;
                const splitPace = Math.floor(split.moving_time / split.distance)
                split.pace = splitPace;

                var milesSplit = miles[j].filter(s => s.pace === split.pace);
                if (miles[j].length === 0 || milesSplit.length === 0) {
                    miles[j].push({
                        pace: split.pace,
                        count: 1,
                        runIds: activity.id.toString(),
                    });
                } else {
                    milesSplit[0].count += 1;
                    milesSplit[0].runIds += ` ${activity.id}`;
                }
                allSplits.push([j+1, split.pace]);

                const squaredPace = Math.pow(split.pace, 2);
                splitTimes[j] += split.pace;
                splitSquaredTimes[j] += squaredPace;
                splitCounts[j] += 1;

                allSplitsTimes += split.pace;
                allSplitsSquaredTimes += squaredPace;

                activity.splitTimes += split.pace;
                activity.splitSquaredTimes += squaredPace;
            }

            activity.splitTimeAverage = activity.splitTimes / activity.splits.length;
            activity.splitSquaredTimeAverage = activity.splitSquaredTimes / activity.splits.length;
            activity.splitTimeStandardDeviation = Math.pow(activity.splitSquaredTimeAverage - Math.pow(activity.splitTimeAverage, 2), 0.5);
        }

        allSplitsAverageTime = allSplitsTimes / allSplits.length;
        allSplitsAverageSquaredTime = allSplitsSquaredTimes / allSplits.length;
        const allSplitsStandardDeviation = Math.pow(allSplitsAverageSquaredTime - Math.pow(allSplitsAverageTime, 2), 0.5);

        for(let i = 0; i < maxDistanceCeiling; i++) {
            splitAverageTimes[i] = splitTimes[i] / splitCounts[i];
            const averageSquaredTimes = splitSquaredTimes[i] / splitCounts[i];
            splitStandardDeviations[i] = Math.pow(averageSquaredTimes - Math.pow(splitAverageTimes[i], 2), 0.5);
        }

        for(let i = 0; i < currentFilteredData.length; i++) {
            const activity = currentFilteredData[i];
            for(let j = 0; j < activity.splits.length; j++) {
                const split = activity.splits[j];
                split.runStandardDeviation = ((split.pace || 0) - (activity.splitTimeAverage || 0)) / (activity.splitTimeStandardDeviation || 0);
                split.overallStandardDeviation = (((split.pace || 0) - allSplitsAverageTime) / allSplitsStandardDeviation)
                split.mileStandardDeviation = (splitStandardDeviations[j] === 0)
                    ? 0
                    : (((split.pace || 0) - splitAverageTimes[j]) / splitStandardDeviations[j])
            }
        }

        const maxSplitPace = d3.max(
            currentFilteredData.map(
                a => d3.max(
                    a.splits.map(s => s.pace || 0).filter(s => s !== 0)
                ) || 0)) || 0;
        const minSplitPace = d3.min(
            currentFilteredData.map(
                a => d3.min(
                    a.splits.map(s => s.pace || 0).filter(s => s !== 0)
                ) || 0)) || 0;

        averagePace = averagePace / currentFilteredData.length;
        const maxDiffX = d3.max(currentFilteredData.map(a => a.diffX || 0));
        const maxDiffY = d3.max(currentFilteredData.map(a => a.diffY || 0));
        setActivityStatistics({
            maxDistance,
            maxDuration,
            maxSplitPace,
            minSplitPace,
            minPace: minPace || 0,
            maxPace: maxPace || 0,
            maxDiffX: maxDiffY || 0,
            maxDiffY: maxDiffX || 0,
            averagePace,
        });
        setFilteredData(currentFilteredData);
    }, [data, startDate, endDate, filter]);

    const updateHistory = useCallback((searchParams: { [key: string]: string }) => {
        const pathname = location.pathname;
        const search = `?${new URLSearchParams(searchParams).toString()}`;
        history.push({ pathname, search });
    }, [location.pathname, history])

    const setDateRange = useCallback((dateRange: [Date, Date]) => {
        const [updatedStartDate, updatedEndDate] = dateRange;
        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);
    }, []);

    useEffect(() => {
        if (startDate === null || endDate === null) {
            return;
        }
        const searchParams: { [key: string]: string } = {
            start: ToISOStringLocal(startDate),
            end: ToISOStringLocal(endDate),
        };
        if (dataViewName) {
            searchParams['view'] = dataViewName;
        }
        updateHistory(searchParams);
    }, [dataViewName, startDate, endDate, updateHistory]);

    const toggleFilterValue = useCallback((clickedFilter: string) => {
        const updatedFilter = [...filter];
        const indexOfFilter = updatedFilter.indexOf(clickedFilter);
        if (indexOfFilter >= 0) {
            updatedFilter.splice(indexOfFilter);
        } else {
            updatedFilter.push(clickedFilter);
        }
        setFilter(updatedFilter);
    }, [filter]);

    const sanitizeCache = useCallback(async (currentAthleteId: string) => {
        const t = toast.loading('Sanitizing cache');
        const cachedPaths: PathProperties[] = await GetFromCacheOrEmpty(cache, `paths/${currentAthleteId}`, []);
        const sanitizedPaths: PathProperties[] = [];
        const pathActivities: number[] = [];
        const points: number[][] = [];
        for (var i = 0; i < cachedPaths.length; i++) {
            const { activityId, color, coordinates } = cachedPaths[i];
            if (pathActivities.indexOf(activityId) >= 0) {
                continue;
            }
            pathActivities.push(activityId);
            sanitizedPaths.push({
                color,
                activityId,
                coordinates,
            });
            Array.prototype.push.apply(points, coordinates);
        }
        await PutObjectInCache(cache, `paths/${currentAthleteId}`, sanitizedPaths);
        await PutObjectInCache(cache, `points/${currentAthleteId}`, points);
        toast.success('Cache sanitized!', { id: t });
    }, [cache]);

    const loadData = useCallback(async () => {
        const t = toast.loading('Loading Activities List');
        setLoading(true);
        if (!athleteId) {
            toast.error('No athleteId found', { id: t });
            setLoading(false);
            return
        }
        
        const rawData: RawActivityData[]|Record<string, string> = await GetFromCacheJSON(cache, GetAthleteActivitiesUrl(athleteId));
        toast.loading('Parsing activities...', { id: t });

        if ((rawData as Record<string, string>).response_type === 'code') {
            const params = new URLSearchParams(rawData as Record<string, string>);
            const stravaAuthFullUrl = `${STRAVA_AUTH_URL}?${params.toString()}`;
            window.location.replace(stravaAuthFullUrl);
            return;
        }

        const parsedData = (rawData as RawActivityData[])
            .map(ParseRawActivityData)
            .sort((a: ActivityData, b: ActivityData) => {
                return (a.date < b.date) ? -1 : 1;
            });

        const currentMinDate = (parsedData[0] || { date: new Date() }).date;
        const currentMaxDate = (parsedData[parsedData.length - 1] || { date: new Date() }).date;

        setData(parsedData);
        setMinDate(currentMinDate);
        setMaxDate(currentMaxDate);
        setDateRange([currentMinDate, currentMaxDate]);

        toast.success('Parsed activities!', { id: t });
    }, [cache, athleteId, setDateRange]);

    const clearCache = useCallback(async () => {
        if (athleteId) {
            await cache.delete(GetAthleteActivitiesUrl(athleteId));
            await cache.delete(GetAthleteUrl(athleteId));
            await cache.delete(GetAthleteUrl(athleteId, true));
        }

        await loadData();
        if (athleteId) {
            await sanitizeCache(athleteId.toString());
        }
    }, [athleteId, loadData, cache, sanitizeCache]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <>
            {athleteId
                ? (<>
                <ShrinkingSideMenu
                    forceMenuOpen={menuIsOpen}
                    menuItems={Object.keys(DATA_VIEWS).map(dataView => {
                        const { icon, name, color } = DATA_VIEWS[dataView];
                        return {
                            icon: (
                                <FontAwesomeIcon
                                    icon={icon}
                                    inverse={true}
                                    size="2x"
                                    style={{
                                        display: 'block',
                                        height: '100%',
                                        margin: 'auto',
                                    }}
                                />
                            ),
                            title: (
                                <span
                                    style={{
                                        color: '#FFF',
                                    }}
                                >
                                    {name}
                                </span>
                            ),
                            key: dataView,
                            color: color,
                            onClick: () => {
                                setDataViewName(dataView)
                                setMenuIsOpen(false);
                            },
                        };
                    })}
                >
                    <Container style={{ color: 'hsla(0, 100%, 100%, 0.89)', textAlign: 'center' }}>
                        <h2>Activity Types</h2>
                        <Row>
                            <Col sm={12}>
                                <ButtonGroup
                                    toggle={true}
                                    style={{ marginBottom: '8px' }}
                                >
                                    <Button
                                        type="radio"
                                        value="Run"
                                        active={filter.includes('Run')}
                                        onClick={() => toggleFilterValue('Run')}
                                    >
                                        <FontAwesomeIcon icon={faRunning} />
                                    </Button>
                                    <Button
                                        type="radio"
                                        value="Ride"
                                        active={filter.includes('Ride')}
                                        onClick={() => toggleFilterValue('Ride')}
                                    >
                                        <FontAwesomeIcon icon={faBiking} />
                                    </Button>
                                    <Button
                                        type="radio"
                                        value="Hike"
                                        active={filter.includes('Hike')}
                                        onClick={() => toggleFilterValue('Hike')}
                                    >
                                        <FontAwesomeIcon icon={faHiking} />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <h2>Date Filter</h2>
                        <Row>
                            <Col sm={12}>
                                <DatePicker
                                    customInput={<CustomInput startDate={startDate || new Date()} endDate={endDate || new Date()} />}
                                    selected={startDate}
                                    onChange={setDateRange}
                                    selectsRange
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    scrollableYearDropdown={true}
                                    shouldCloseOnSelect={false}
                                >
                                    <div style={{ margin: '0px 8px' }}>
                                        <div>
                                            <Button
                                                size="sm"
                                                style={{ float: 'left', marginBottom: '8px' }}
                                                variant="secondary"
                                                onClick={() => {
                                                    setDateRange([minDate, endDate || maxDate]);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: '4px' }}/>
                                                First
                                            </Button>
                                            <Button
                                                size="sm"
                                                style={{ float: 'right' }}
                                                variant="secondary"
                                                onClick={() => {
                                                    setDateRange([startDate || minDate, maxDate]);
                                                }}
                                            >
                                                Last
                                                <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: '4px' }}/>
                                            </Button>
                                        </div>                                        
                                    </div>
                                </DatePicker>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <RefreshActivitiesButton
                                    athleteId={athleteId}
                                    onSuccess={clearCache}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ShrinkingSideMenu>
                {
                    dataViewName !== null &&
                    activityStaticstics !== undefined &&
                    filteredData !== undefined &&
                    athleteId !== undefined &&
                        createElement(DATA_VIEWS[dataViewName].dataView, {
                            activities: filteredData,
                            athleteId,
                            statistics: activityStaticstics,
                        })
                }
                </>)
                : loading || <SignIn/>
            }
        </>
    )
}

export default Wrapper;

interface RefreshActivitiesButtonProps {
    athleteId: number;
    onSuccess: () => void;
}

const RefreshActivitiesButton: React.FC<RefreshActivitiesButtonProps> = ({ athleteId, onSuccess }) => {
    const [queuing, setQueuing] = useState(false);
    const [polling, setPolling] = useState(false);

    const onClick = useCallback(async () => {
        setQueuing(true);
        var t = toast.loading('Queuing activities refresh');
        try {
            var lambdaResult: LambdaInvokeResponseMetadata = await (await fetch (GetAthleteNodesUpdateUrl(athleteId), { method: 'POST' })).json();
            setQueuing(false);
            toast.loading('Waiting for refresh to complete', { id: t });
            var getLambdaResult = () => { return GetFileContentsOrUndefeind(GetJobUrl(lambdaResult.requestId, 'nodesAthleteUpdate')) };
            setPolling(true);
            var result = await waitFor(getLambdaResult, 5 * 60 * 1000);
            if (result === undefined || result === "Failed") {
                toast.error('Activities refresh failed', { id: t });
            } else {
                toast.success('Activities refresh completed!', { id: t });
                onSuccess();
            }
        } catch (ex) {
            toast.error('Error queuing or polling for activities refresh', { id: t });
        }
        setPolling(false);
    }, [athleteId, onSuccess]);

    return (
        <Button
            variant={queuing || polling ? 'outline-info' : 'primary'}
            size="sm"
            style={{ marginTop: '8px' }}
            onClick={onClick}
            disabled={queuing || polling}
        >
            Refresh Activities
        </Button>
    );
}

