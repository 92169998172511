import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
// import reportWebVitals from './reportWebVitals';
import './css/main.css';
import './lib/bootstrap/bootstrap.min.css';
import { PROJECTS_MANIFEST } from './projects/Manifest';
import Photos from './projects/Photos';
import Home from './Home';
import PeakBagger from './projects/PeakBagger';
import MyPeaks from './projects/PeakBagger/MyPeaks';
import { Toaster } from 'react-hot-toast';
import CrystalParking from './projects/CrystalParking';
import Semantle from './projects/Semantle';
import Music from './projects/Music';
import TempReader from './projects/TempReader';
import YTMND from './projects/YTMND';
import MusicPlayer from './projects/Music/MusicPlayer';
import Finances from './projects/Finances';
import GPXRouteComparison from './projects/GPXRouteComparison';
import SVDiff from './projects/SVDiff';

const NotFound: React.FC = () => {
  return (
    <h1 style={{ textAlign: 'center' }}>404 yo</h1>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Grover's Site</title>
        <link rel="canonical" href="https://jrgrover.com" />
      </Helmet>
      <Toaster />
      <Switch>
        <Route exact path="/" component={Home} />
        {
          PROJECTS_MANIFEST.Complete.experiments
            .filter(experiment => experiment.component)
            .map(experiment => {
              return (
                <Route
                  exact={true}
                  key={experiment.href}
                  path={experiment.href}
                  component={experiment.component}
                />
              );
            })
        }
        {
          PROJECTS_MANIFEST.Development.experiments
            .filter(experiment => experiment.component)
            .map(experiment => {
              return (
                <Route
                  exact={true}
                  key={experiment.href}
                  path={experiment.href}
                  component={experiment.component}
                />
              );
            })
        }
        <Route path="/music/:songName" component={MusicPlayer} />
        <Route path="/music" component={Music} />
        <Route path="/photos/:albumName?" component={Photos} />
        <Route path="/peakbagger/myPeaks">
          <MyPeaks/>
        </Route>
        <Route path="/peakbagger">
          <PeakBagger/>
        </Route>
        <Route path="/crystalParking">
          <CrystalParking/>
        </Route>
        <Route path="/semantle">
          <Semantle/>
        </Route>
        <Route path="/temp/:session?" component={TempReader} />
        <Route path="/27" component={YTMND} />
        <Route path="/finances" component={Finances} />
        <Route path="/gpxRouteComparison" component={GPXRouteComparison} />
        <Route path="/svdiff" component={SVDiff} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
