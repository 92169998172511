import { useCallback, useEffect, useMemo, useState } from "react";
import { ModalBody, Nav, Tab, Table } from "react-bootstrap";
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { FormControl, InputGroup, Modal } from "react-bootstrap";
import { debounce } from "src/lib/Utils/Debounce";
import { GetAthleteCityNodesUpdateUrl, GetCityNodesUrl, GetCitySearchUrl, GetFileContentsOrUndefeind, GetJobUrl, LambdaInvokeResponseMetadata, waitFor } from "../Common";
import toast from "react-hot-toast";
import { faCheck, faPlus, faRedoAlt, faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CombinedCityData } from "../HeatmapUtils";
import { getTimeObjectFromTotal } from "src/lib/Countdown";

interface SearchResult {
	display_name: string;
	osm_id: number;
}

interface CitySearchModalProps {
	cityMapping: { [city: string]: CombinedCityData }
	onHide: () => void;
	athleteId: number;
	onCityAdditon: () => void;
	onCityRefresh: () => void;
	onAthleteCityRefresh: (cityId: number) => void;
}

const CitySearchModal: React.FC<CitySearchModalProps> = ({
	onHide,
	cityMapping,
	athleteId,
	onCityAdditon,
	onCityRefresh,
	onAthleteCityRefresh
}) => {
	const [search, setSearch] = useState('');
	const [searchResults, setSearchResults] = useState<SearchResult[]>([])

	const searchForCity = useCallback(async (citySearch: string) => {
		if (citySearch === '') {
			return;
		}
		setSearchResults(await (await fetch(GetCitySearchUrl(citySearch))).json())
	}, []);

	const debounceSearch = useMemo(
        () =>
            debounce(
                (updatedSearch: string) => { searchForCity(updatedSearch); },
                300
            ),
        [searchForCity]
    );

	useEffect(() => {
		debounceSearch(search);
	}, [search, debounceSearch]);

	return (
		<Modal
			show={true}
			onHide={onHide}
			size="lg"
		>
			<ModalHeader closeButton={true}>
				<h4>
					Add/Refresh City
				</h4>
			</ModalHeader>
			<ModalBody>
				<Tab.Container
					id="CityModalTabs"
					transition={false}
					defaultActiveKey="CitiesList"
				>
					<Nav variant="tabs">
						<Nav.Item>
                            <Nav.Link eventKey="CitiesList">
								Cities List
								<FontAwesomeIcon
									style={{ marginLeft: '4px', cursor: 'pointer' }}
									onClick={onCityRefresh}
									icon={faRedoAlt}
								/>
							</Nav.Link>
                        </Nav.Item>
						<Nav.Item>
                            <Nav.Link eventKey="Search">Search</Nav.Link>
                        </Nav.Item>
					</Nav>
					<Tab.Content>
						<Tab.Pane eventKey="CitiesList">
							<Table striped>
								<thead>
									<tr>
										<th>Name</th>
										<th style={{ textAlign: 'right' }}>OSM ID</th>
										<th style={{ textAlign: 'right' }}>City Last Updated</th>
										<th style={{ textAlign: 'right' }}>Progress Last Updated</th>
									</tr>
								</thead>
								<tbody>
									{Object.entries(cityMapping).map(([key,v]) => {
										var currentDateTime = (new Date()).getTime();
										var cityDateDiff = (new Date(currentDateTime - (new Date(v.updated_at)).getTime())).getTime();
										var nodesDateDiff = (new Date(currentDateTime - (new Date(v.nodes_updated_at)).getTime())).getTime();
										return (
											<tr key={key}>
												<td>{v.name}</td>
												<td style={{ textAlign: 'right' }}>{key}</td>
												<td style={{ textAlign: 'right' }}>
													<span style={{ marginRight: '4px' }}>
														{getYearsDaysAgoStringFromMilliseconds(cityDateDiff) || 'today'}
													</span>
													<AddRefreshCityButton
														athleteId={athleteId}
														cityId={parseInt(key)}
														existing={true}
														onCityRefresh={onCityRefresh}
													/>
												</td>
												<td style={{ textAlign: 'right' }}>
													<span style={{ marginRight: '4px' }}>
														{v.nodes_updated_at !== 0
															? (getYearsDaysAgoStringFromMilliseconds(nodesDateDiff) || 'today')
															: 'Unknown'}
													</span>
													<RefreshAthleteCityButton
														athleteId={athleteId}
														cityId={parseInt(key)}
														onAthleteCityRefresh={onAthleteCityRefresh}
													/>
												</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Tab.Pane>
						<Tab.Pane eventKey="Search">
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>
										<FontAwesomeIcon
											icon={faSearch}
										/>
									</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl
									as="input"
									type="text"
									placeholder="City Name"
									value={search}
									onChange={(e) => {
										setSearch(e.currentTarget.value);
									}}
								/>
							</InputGroup>
							<Table
								striped
							>
								<thead>
									<tr>
										<th>Name</th>
										<th style={{ textAlign: 'right' }}>OSM ID</th>
										<th style={{ textAlign: 'center' }}>Add City</th>
									</tr>
								</thead>
								<tbody>
									{searchResults.filter(result => !(result.osm_id.toString() in cityMapping))
										.map(result => {
											const { osm_id, display_name } = result;
											return (
												<tr key={osm_id}>
													<td>{display_name}</td>
													<td style={{ textAlign: 'right' }}>{osm_id}</td>
													<td style={{ textAlign: 'center' }}>
														<AddRefreshCityButton
															athleteId={athleteId}
															cityId={osm_id}
															onCityAddition={onCityAdditon}
															onAthleteCityRefresh={onAthleteCityRefresh}
														/>
													</td>
												</tr>
											);
										})
									}
								</tbody>
							</Table>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</ModalBody>
		</Modal>		
	);
}

export default CitySearchModal;

interface AddRefreshCityButtonProps {
	athleteId: number;
	cityId: number;
	existing?: boolean;
	onCityAddition?: () => void;
	onAthleteCityRefresh?: (cityId: number) => void;
	onCityRefresh?: () => void;
}

const AddRefreshCityButton: React.FC<AddRefreshCityButtonProps> = ({
	athleteId,
	cityId,
	existing = false,
	onCityAddition,
	onAthleteCityRefresh,
	onCityRefresh,
}) => {
	const [addedOrRefreshed, setAddedOrRefreshed] = useState(false);
	const [loading, setLoading] = useState(false);

	const onClick = useCallback(async () => {
		setLoading(true);
		const result = await QueueLambdaViaUrlAndPoll({
			start: `${existing ? 'Refreshing' : 'Adding'} city (${cityId})...`,
			queued: `City ${existing ? 'refresh' : 'add'} queued, waiting for completion`,
			failed: `City ${existing ? 'refresh' : 'add'} failed`,
			exception: `Failed to ${existing ? 'refresh' : 'add'} city`,
			success: `City ${existing ? 'refreshed' : 'added'}!`,
		}, GetCityNodesUrl(cityId), 'nodesCityUpdate');

		if (result) {
			if (!existing && onCityAddition) {
				// TODO: Refresh Athlete City Nodes Here
				onCityAddition();
				var refreshResult = await RefreshAthleteCity({
					start: `Refreshing athlete nodes for city (${cityId})...`,
					queued: `Waiting for nodes update to complete`,
					failed: `Athlete nodes update for city failed`,
					exception: `Failed to queue athlete update for city`,
					success: 'Nodes update complete, waiting map generation',
				}, athleteId, cityId);
				if (refreshResult) {
					if (onAthleteCityRefresh) {
						onAthleteCityRefresh(cityId);
					}
				}
			} else if (onCityRefresh) {
				onCityRefresh();
			}
			setAddedOrRefreshed(true);
		}

		setLoading(false);
	}, [cityId, athleteId, existing, onCityAddition, onCityRefresh, onAthleteCityRefresh]);

	return (
		<FontAwesomeIcon
			onClick={onClick}
			pointerEvents={(loading || addedOrRefreshed) ? 'none' : 'auto'}
			icon={loading
				? faSpinner
				: addedOrRefreshed
					? faCheck
					: existing
						? faRedoAlt
						: faPlus}
			spin={loading}
			size="sm"
			style={{ cursor: 'pointer' }}
		/>
	);
}

interface LambdaQueuePollMessages {
	start: string;
	queued: string;
	failed: string;
	exception: string;
	success: string;
}

const PollForLambdaJobUrl = async (url: string, failed: string, success: string, toastId: string) => {
	var getLambdaResult = () => { return GetFileContentsOrUndefeind(url) };
	var result = await waitFor(getLambdaResult, 5 * 60 * 1000);
	if (result === undefined || result === "Failed") {
		toast.error(failed, { id: toastId });
	} else {
		toast.success(success, { id: toastId });
		return result;
	}
	return undefined;
}

const QueueLambdaViaUrlAndPoll = async (messages: LambdaQueuePollMessages, url: string, jobName: string) => {
	var t = toast.loading(messages.start);
	try {
		var lambdaResult: LambdaInvokeResponseMetadata = await (await fetch(url, { method: 'POST' })).json();
		toast.loading(messages.queued, { id: t });
		return await PollForLambdaJobUrl(GetJobUrl(lambdaResult.requestId, jobName), messages.failed, messages.success, t);
	} catch (ex) {
		toast.error(messages.exception, { id: t });
	}
	return undefined;
}

const RefreshAthleteCity = async (messages: LambdaQueuePollMessages, athleteId: number, cityId: number) => {
	const nodesUpdateResult = await QueueLambdaViaUrlAndPoll(messages, GetAthleteCityNodesUpdateUrl(athleteId, cityId), 'nodesAthleteUpdateCityNodes');
	if (nodesUpdateResult) {
		var t = toast.loading(`Generating progress map for city (${cityId})`)
		return await PollForLambdaJobUrl(
			GetJobUrl(nodesUpdateResult as string, 'nodesAthleteUpdateCityNodesTiles'),
			'Map generation failed',
			'Map generation complete!',
			t
		);
	}
}

interface RefreshAthleteCityButtonProps {
	athleteId: number;
	cityId: number;
	existing?: boolean;
	onAthleteCityRefresh: (cityId: number) => void;
}

const RefreshAthleteCityButton: React.FC<RefreshAthleteCityButtonProps> = ({
	athleteId,
	cityId,
	onAthleteCityRefresh,
}) => {
	const [loading, setLoading] = useState(false);

	const onClick = useCallback(async () => {
		setLoading(true);
		var result = await RefreshAthleteCity({
			start: `Refreshing athlete nodes for city (${cityId})...`,
			queued: `Waiting for nodes update to complete`,
			failed: `Athlete nodes update for city failed`,
			exception: `Failed to queue athlete update for city`,
			success: 'Nodes update complete, waiting map generation',
		}, athleteId, cityId);
		if (result) {
			if (onAthleteCityRefresh) {
				onAthleteCityRefresh(cityId);
			}
		}
		setLoading(false);
	}, [athleteId, cityId, onAthleteCityRefresh]);

	return (
		<FontAwesomeIcon
			onClick={onClick}
			pointerEvents={(loading) ? 'none' : 'auto'}
			icon={loading
				? faSpinner
				: faRedoAlt}
			spin={loading}
			size="sm"
			style={{ cursor: 'pointer' }}
		/>
	);
}

const getYearsDaysAgoStringFromMilliseconds = (t: number) => {
	const timeObject = getTimeObjectFromTotal(t);
	const years = timeObject.years !== 0 ? timeObject.years + 'y ' : '';
	const days = timeObject.days !== 0 ? timeObject.days + 'd' : '';
	return `${years}${days}`;
}