import { Route } from '@mapbox/mapbox-sdk/services/directions';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import MapboxTerrainCache from 'src/lib/Utils/MapboxTerrainCache';
import { MeterDistanceBetweenLatitudeLongitude, SmoothProfile } from 'src/lib/Utils/Maps';
import { Position } from 'geojson';
import * as d3 from 'd3';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagUsa } from '@fortawesome/free-solid-svg-icons';
import SimplyGraph from 'src/lib/d3/SimplyGraph';

export const MetersToMiles = (meters: number) => {
    return meters * 0.000621371;
}

export const MetersToFeet = (meters: number) => {
    return meters * 3.28084;
}

const MilesToMeters = (miles: number) => {
    return miles / 0.000621371;
}

const D3_BISECTOR = d3.bisector(
    (d: [number,number]) => {
        return d[0]
    });

interface RouteDetailsProps {
    route: Route[];
    backgroundColor?: string;
    onMouseOver?: () => void;
    onMouseMove?: (position: Position) => void;
    onMouseOut?: () => void;
    setDistanceMarkers?: (metric: boolean, locations: Position[]) => void;
}

const RouteDetails: React.FC<RouteDetailsProps> = ({
    route,
    backgroundColor = '#CCC',
    onMouseOver = () => {},
    onMouseMove = () => {},
    onMouseOut = () => {},
    setDistanceMarkers = () => {},
}) => {
    const TerrainCache = useRef(new MapboxTerrainCache());
    const [profile, setProfile] = useState<[number,number][]>([]);
    const [profileCoordinates, setProfileCoordinates] = useState<Position[]>([]);
    const [smoothedProfile, setSmoothedProfile] = useState<[number, number][]>([]);
    const [gain, setGain] = useState(0);
    const [smoothedGain, setSmoothedGain] = useState(0);
    const [metric, setMetric] = useState(true);

    const loadTileDataAndCreateProfile = useCallback(async () => {
        const ZOOM = 15;
        const path = route.reduce((accumulator: [number,number][], segment: Route) => {
            const { coordinates } = segment.geometry;
            const routeLineCoordinates: [number,number][] = (coordinates as [number, number][]).map(coordinate => {
                return [coordinate[0], coordinate[1]];
            });
            return accumulator.concat(routeLineCoordinates);
        }, []);
        await TerrainCache.current.loadTilesForLatitudeLongitudePathAtZoom(path, ZOOM);

        const segmentCoordinates = route.reduce((accumulator: Position[], segment: Route) => {
            return accumulator.concat(segment.geometry.coordinates as Position[]);
        }, []);
        let cumulativeDistance = 0;
        const firstSegmentCoordinatesAltitude = segmentCoordinates.length > 0
            ? TerrainCache.current.getAltitudeForZoomLatitudeLongitude(ZOOM, segmentCoordinates[0][1], segmentCoordinates[0][0])
            : 0;
        const updatedProfile: [number, number][] = [[
            0,
            firstSegmentCoordinatesAltitude
        ]];

        for (var i = 1; i < segmentCoordinates.length; i++) {
            const coords = segmentCoordinates[i];
            const previousCoords = segmentCoordinates[i-1];
            let distanceToPrevious = MeterDistanceBetweenLatitudeLongitude({ lat: previousCoords[1], lon: previousCoords[0] }, { lat: coords[1], lon: coords[0] });
            if (distanceToPrevious > 50) {
                // TODO: This is not entirely correct. Do it like this instead:
                // https://www.movable-type.co.uk/scripts/latlong.html#midpoint

                let halfNextCoods = [
                    (previousCoords[0] + coords[0]) / 2,
                    (previousCoords[1] + coords[1]) / 2
                ];
                segmentCoordinates.splice(i, 0, halfNextCoods);
                i--;
                continue;
            }
            cumulativeDistance += distanceToPrevious;
            updatedProfile.push([
                cumulativeDistance,
                TerrainCache.current.getAltitudeForZoomLatitudeLongitude(ZOOM, coords[1], coords[0])
            ]);
        }

        setProfile(updatedProfile);
        setProfileCoordinates(segmentCoordinates);
        setSmoothedProfile(SmoothProfile(updatedProfile, 1));
    }, [route]);

    useEffect(() => {
        loadTileDataAndCreateProfile();
    }, [loadTileDataAndCreateProfile]);

    useEffect(() => {
        let updatedGain = 0;
        let updatedSmoothedGain = 0;

        for (var i = 1; i < profile.length; i++) {
            const previous = profile[i-1][1];
            const current = profile[i][1];
            if (previous > current) {
                updatedGain += previous - current;
            }
        }

        for (var j = 1; j < smoothedProfile.length ; j++) {
            const previous = smoothedProfile[j-1][1];
            const current = smoothedProfile[j][1];
            if (previous > current) {
                updatedSmoothedGain += previous - current;
            }
        }

        setGain(updatedGain);
        setSmoothedGain(updatedSmoothedGain);
    }, [profile, smoothedProfile]);

    useEffect(() => {
        if (profile.length === 0) {
            return;
        }
        const totalDistance = profile[profile.length - 1][0];
        const markers = Math.floor(metric ? totalDistance / 1000 : MetersToMiles(totalDistance));
        const locations: Position[] = [];
        for (var i = 1; i <= markers; i++) {
            const distance = metric ? i * 1000 : MilesToMeters(i);
            const index = D3_BISECTOR.right(profile, distance);
            locations.push(profileCoordinates[index]);
        }
        setDistanceMarkers(metric, locations);
    }, [metric, profile, profileCoordinates, setDistanceMarkers]);

    return (
        <div
            style={{
                backgroundColor: backgroundColor,
            }}
        >
            <div style={{ display: 'flex' }}>
                <div style={{ margin: '5px', position: 'fixed' }}>
                    <Button
                        value="metric"
                        variant="outline-secondary"
                        size="sm"
                        active={!metric}
                        onClick={() => setMetric(!metric)}
                    >
                        <FontAwesomeIcon icon={faFlagUsa}/>
                    </Button>
                </div>
                <div
                    style={{
                        width: '100%',
                        height: '200px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            marginRight: 60,
                            marginLeft: 60,
                            fontWeight: 'lighter',
                            flexGrow: 0,
                        }}
                    >
                        <p style={{ display: 'inline', marginBottom: 0 }}>Profile (<span style={{ color: 'rgba(102,102,255,0.75)'}}>Smoothed Profile</span>)</p>
                        <span style={{ float: 'right' }}>
                            ↗ {metric ? `${gain.toFixed(2)} m` : `${MetersToFeet(gain).toFixed(2)} ft`} ({metric ? `${smoothedGain.toFixed(2)} m` :  `${MetersToFeet(smoothedGain).toFixed(2)} ft`})
                        </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <SimplyGraph
                            graphLines={[
                                {
                                    name: 'Profile',
                                    dataPoints: profile.map(datum => {
                                        return {
                                            metricDistance: datum[0] / 1000,
                                            imperialDistance: MetersToMiles(datum[0]),
                                            metricAltitude: datum[1],
                                            imperialAltitude: MetersToFeet(datum[1]),
                                        }
                                    }),
                                    width: 1,
                                },
                                {
                                    name: 'Smoothed Profile',
                                    dataPoints: smoothedProfile.map(datum => {
                                        return {
                                            metricDistance: datum[0] / 1000,
                                            imperialDistance: MetersToMiles(datum[0]),
                                            metricAltitude: datum[1],
                                            imperialAltitude: MetersToFeet(datum[1]),
                                        }
                                    }),
                                    width: 1,
                                }
                            ]}
                            xAccessor={metric ? 'metricDistance' : 'imperialDistance'}
                            yAccessor={metric ? 'metricAltitude' : 'imperialAltitude'}
                            xScaleOverride={d3.scaleLinear()}
                            margins={{ top: 0, right: 60, bottom: 40, left: 60 }}
                            tickColor='#000'
                            xAxisLabel={metric ? 'km' : 'mi'}
                            yAxisLabel={metric ? 'm' : 'ft'}
                            yTicksArguments={[5, '.0d']}
                            colors={d3.scaleOrdinal(['#000', 'rgba(102,102,255,0.75)'])}
                            lineCurve={d3.curveLinear}
                            rotateYAxisLabel={false}
                            xAxisMaxExtent={true}
                            disableLegend={true}
                            globalFocus='Profile'
                            yAxisFormat={(a: any) => `${a.toFixed()} ${metric ? 'm' : 'ft'}`}
                            onMouseOver={onMouseOver}
                            onMouseMove={(index: number) => {
                                onMouseMove(profileCoordinates[index])
                            }}
                            onMouseOut={onMouseOut}
                            omitLastDataLabel
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RouteDetails;