import * as d3 from "d3";
import { useEffect, useRef } from "react";

const AnimatedPath:React.FC<React.SVGProps<SVGPathElement>> = ({
	className,
	strokeWidth,
	fill,
	stroke,
	opacity,
	onMouseEnter,
	onMouseLeave,
	onMouseMove,
	clipPath,
	d,
}) => {
	const pathRef = useRef<SVGPathElement>(null);

	useEffect(() => {
		d3.select(pathRef.current)
            .transition()
            .attr("d", d || '');
	})
    
    return (
		<path
			ref={pathRef}
			className={className}
			strokeWidth={strokeWidth}
			fill={fill}
			stroke={stroke}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onMouseMove={onMouseMove}
			clipPath={clipPath}
			opacity={opacity}
			style={{
				// transition: 'all .2s',
			}}
		/>
	);
}

export default AnimatedPath;