import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faBiking,
    faCheckCircle,
    faExternalLinkAlt,
    faFilter,
    faHiking,
    faHourglassHalf,
    faMinusCircle,
    faMountain,
    faQuestionCircle,
    faRunning,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortNumericDown,
    faSortNumericUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, FormControl, InputGroup, OverlayTrigger, Popover, PopoverContent, PopoverTitle, Row, Table } from 'react-bootstrap';
import { debounce } from 'src/lib/Utils/Debounce';
import { secondsToString } from 'src/lib/Utils/Strings';
import { CACHE_NAME, DelayForMilliseconds, GetActivityUrl, GetCacheMatch, GetFromCacheJSON, STRAVA_URL, ToISOStringLocal } from './Common';
import { ActivityData, DataViewPropsBase } from './types';
import { Range, getTrackBackground } from 'react-range';

type sortTypes = keyof ActivityData;

const List: React.FC<DataViewPropsBase> = ({ athleteId, activities }) => {
    const [sort, setSort] = useState<sortTypes>('date');
    const [cache, setCache] = useState<Cache>();
    const [reverseSort, setReverseSort] = useState(false);
    const [filter, setFilter] = useState<string>();
    const [distanceMin, setDistanceMin] = useState(0);
    const [distanceMax, setDistanceMax] = useState(Infinity);
    const [durationMin, setDurationMin] = useState(0);
    const [durationMax, setDurationMax] = useState(Infinity);
    const [activitesMaxDistance, setActivitiesMaxDistance] = useState(Infinity);
    const [activitiesMaxDuration, setActivitiesMaxDuration] = useState(Infinity);
    
    useEffect(() => {
        const maxDistance = activities.length === 0
            ? Infinity
            : Math.ceil(
                Math.max(
                    ...activities.map(a => a.distance)
                )
            )
        setDistanceMax(maxDistance);
        setActivitiesMaxDistance(maxDistance);
        const maxDuration = activities.length === 0
            ? Infinity
            : Math.ceil(
                Math.max(
                    ...activities.map(a => a.duration)
                )
            );
        setDurationMax(maxDuration);
        setActivitiesMaxDuration(maxDuration);
    }, [activities]);

    useEffect(() => {
        (async () => {
            const cache = await window.caches.open(CACHE_NAME);
            setCache(cache);
        })();
    }, []);

    const setSortAndReverse = useCallback((clickedSort: sortTypes) => {
        setSort(clickedSort);
        if (clickedSort !== sort) {
            setReverseSort(false);
        } else {
            setReverseSort(!reverseSort);
        }
    }, [sort, reverseSort]);

    const debounceFilter = useMemo(
        () =>
            debounce(
                (updatedFilter: string) => { setFilter(updatedFilter); },
                300
            ),
        [setFilter]
    );

    const filteredSortedActivities = activities.filter((a) => {
        const nameFilterResult = filter !== undefined
            ? (a.name.indexOf(filter) >= 0) || (ToISOStringLocal(a.date).indexOf(filter) >= 0)
            : true;
        const distanceFilterResult = a.distance <= distanceMax && a.distance >= distanceMin;
        const durationFilterResult = a.duration <= durationMax && a.duration >= durationMin;
        return nameFilterResult && distanceFilterResult && durationFilterResult;
    }).sort((a, b) => {
        const aValue = a[sort];
        const bValue = b[sort];
        if (!aValue && !bValue) {
            return 0;
        }
        if (!aValue) {
            return -1;
        }
        if (!bValue) {
            return 1;
        }
        if (aValue === bValue) {
            return 0;
        }
        return reverseSort
            ? (aValue < bValue)
                ? 1
                : -1
            : (aValue > bValue)
                ? 1
                : -1;
    });

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <h1
                        style={{ marginTop: '5px' }}
                    >
                        {
                            Array.from(
                                new Set(
                                    activities.map(
                                        activity => activity.type
                                    )
                                )
                            ).map(
                                type => <span key={type} style={{ marginRight: '2px' }}><ActivityIcon activityType={type}/></span>
                            )
                        }
                        Activities<small> {athleteId}</small>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <Filter
                                    onUpdateDistanceRange={(values: [number,number]) => {
                                        setDistanceMin(values[0]);
                                        setDistanceMax(values[1]);
                                    }}
                                    onUpdateDurationRange={(values: [number,number]) => {
                                        setDurationMin(values[0]);
                                        setDurationMax(values[1]);
                                    }}
                                    distanceMax={activitesMaxDistance}
                                    distanceMin={0}
                                    durationMin={0}
                                    durationMax={activitiesMaxDuration}
                                />
                            </InputGroup.Prepend>
                            <FormControl
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    debounceFilter(event.currentTarget.value);
                                }}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    {filteredSortedActivities.length} activities
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Table
                        striped={true}
                        hover={true}
                        size="sm"
                    >
                        <thead>
                            <tr
                                style={{ cursor: 'pointer' }}
                            >
                                <th
                                    onClick={() => { setSortAndReverse('date') }}
                                >Date {sort === 'date' && <FontAwesomeIcon icon={reverseSort ? faSortNumericUp : faSortNumericDown}/>}</th>
                                <th
                                    onClick={() => { setSortAndReverse('name') }}
                                >Name {sort === 'name' && <FontAwesomeIcon icon={reverseSort ? faSortAlphaDown : faSortAlphaUp}/>}</th>
                                <th
                                    onClick={() => { setSortAndReverse('distance') }}
                                    style={{ textAlign: 'right' }}
                                >Distance {sort === 'distance' && <FontAwesomeIcon icon={reverseSort ? faSortNumericUp : faSortNumericDown}/>}</th>
                                <th
                                    onClick={() => { setSortAndReverse('duration') }}
                                    style={{ textAlign: 'right' }}
                                >Duration {sort === 'duration' && <FontAwesomeIcon icon={reverseSort ? faSortNumericUp : faSortNumericDown}/>}</th>
                                <th
                                    onClick={() => { setSortAndReverse('speed') }}
                                    style={{ textAlign: 'right' }}
                                >Speed <small>avg.</small> {sort === 'speed' && <FontAwesomeIcon icon={reverseSort ? faSortNumericUp : faSortNumericDown}/>}</th>
                                <th
                                    onClick={() => { setSortAndReverse('pace') }}
                                    style={{ textAlign: 'right' }}
                                >Pace <small>avg.</small> {sort === 'pace' && <FontAwesomeIcon icon={reverseSort ? faSortNumericUp : faSortNumericDown}/>}</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSortedActivities.map(activityData => {
                                return (
                                    <ActivityRow
                                        key={activityData.id}
                                        activityData={activityData}
                                        athleteId={athleteId}
                                        cache={cache}
                                    />
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default List;

interface FilterProps {
    onUpdateDistanceRange: (range: [number,number]) => void;
    onUpdateDurationRange: (range: [number,number]) => void;
    distanceMin: number;
    distanceMax: number;
    durationMin: number;
    durationMax: number;
}

const Filter: React.FC<FilterProps> = ({
    onUpdateDistanceRange,
    onUpdateDurationRange,
    distanceMin,
    distanceMax,
    durationMin,
    durationMax,
}) => {
    const [currentDistanceMin, setCurrentDistanceMin] = useState(distanceMin);
    const [currentDistanceMax, setCurrentDistanceMax] = useState(distanceMax);
    const [currentDurationMin, setCurrentDurationMin] = useState(durationMin);
    const [currentDurationMax, setCurrentDurationMax] = useState(durationMax);


    useEffect(() => {
        setCurrentDistanceMax(distanceMax);
    }, [distanceMax]);

    useEffect(() => {
        setCurrentDurationMax(durationMax);
    }, [durationMax]);

    return (<>
        <OverlayTrigger
            placement="bottom"
            trigger="click"
            overlay={<Popover id="popover-basic" style={{ minWidth: '200px' }}>
            <PopoverTitle as="h3">Set Filters</PopoverTitle>
            <PopoverContent style={{ margin: '0 1rem'}}>
              <h5>Distance</h5>
              <RangeSlider
                min={distanceMin}
                max={distanceMax}
                initialMin={currentDistanceMin}
                initialMax={currentDistanceMax}
                onChange={(values: number[]) => {
                    setCurrentDistanceMin(values[0]);
                    setCurrentDistanceMax(values[1]);
                }}
              />
              <h5>Duration</h5>
              <RangeSlider
                min={durationMin}
                max={durationMax}
                initialMin={currentDurationMin}
                initialMax={currentDurationMax}
                onChange={(values: number[]) => {
                    setCurrentDurationMin(values[0]);
                    setCurrentDurationMax(values[1]);
                }}
                formatter={secondsToString}
              />
            </PopoverContent>
          </Popover>}
            onExit={() => {
                onUpdateDistanceRange([currentDistanceMin, currentDistanceMax]);
                onUpdateDurationRange([currentDurationMin, currentDurationMax]);
            }}
        >
            {({ ref, ...triggerHandler }) => (
                <InputGroup.Text
                    {...triggerHandler}
                >
                    <FontAwesomeIcon       
                        icon={faFilter}
                        forwardedRef={ref}
                    />
                </InputGroup.Text>
            )}
        </OverlayTrigger>
    </>);
}

interface RangeSliderProps {
    min: number;
    max: number;
    initialMin: number;
    initialMax: number;
    onChange: (values: number[]) => void;
    formatter?: (value: number) => string;
    step?: number;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
    min,
    max,
    initialMin,
    initialMax,
    onChange,
    step=0.5,
    formatter = (value: number) => value,
}) => {
    const [currentRange, setCurrentRange] = useState([initialMin, initialMax]);

    useEffect(() => {
        setCurrentRange([initialMin, initialMax]);
    }, [initialMin, initialMax])

    return (
        <Range
            step={step}
            min={min}
            max={max}
            values={currentRange}
            onChange={(values: number[]) => {
                setCurrentRange(values);
            }}
            onFinalChange={(values: number[]) => {
                onChange(values);
                setCurrentRange(values);
            }}
            renderTrack={({ props, children }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '6px',
                        width: '100%',
                        marginTop: '3px',
                        marginBottom: '15px',
                        backgroundColor: '#ccc',
                        borderRadius: '4px',
                        background: getTrackBackground({
                            values: currentRange,
                            colors: ['#ccc', '#548BF4', '#ccc'],
                            min,
                            max,
                        }),
                    }}
                >
                    {children}
                </div>
                )}
            renderThumb={({ index, props }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '12px',
                        width: '12px',
                        borderRadius: '4px',
                        backgroundColor: '#999',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {formatter(index === 0 ? currentRange[0] : currentRange[1])}
                    </div>
                </div>
            )}
            />
    )
}

interface ActivityRowProps {
    activityData: ActivityData;
    athleteId: number;
    cache?: Cache;
}

const ActivityRow: React.FC<ActivityRowProps> = ({ activityData, athleteId, cache }) => {
    const {
        date,
        name,
        distance,
        duration,
        speed,
        pace,
        id,
    } = activityData;

    return (
        <tr>
            <td
                style={{ fontFamily: 'monospace' }}
            >{ToISOStringLocal(date)}</td>
            <td
                style={{
                    textOverflow: 'ellipsis',
                    maxWidth: '400px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >{name}</td>
            <td
                style={{ textAlign: 'right', fontFamily: 'monospace' }}
            >{distance.toFixed(2)} <small>mi</small></td>
            <td
                style={{ textAlign: 'right', fontFamily: 'monospace' }}
            >{secondsToString(duration)}</td>
            <td
                style={{ textAlign: 'right', fontFamily: 'monospace' }}
            >{speed.toFixed(2)} <small>mph</small></td>
            <td
                style={{ textAlign: 'right', fontFamily: 'monospace' }}
            >{secondsToString(Math.floor(pace))} <small>/mi</small></td>
            <td>
                <a
                    href={`${STRAVA_URL}${id}`}
                    target="_blank"
                    rel="noreferrer"
                    title="View in Strava"
                >
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="sm"/>
                </a>
                <a
                    href={`/routeVisualizer/#${id}-${athleteId}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ marginLeft: '3px' }}
                    title="View in 3D"
                >
                    <FontAwesomeIcon icon={faMountain}/>
                </a>
                <span style={{ marginLeft: '3px' }}>
                <ActivityCacheIndicator
                    athleteId={athleteId}
                    activityId={activityData.id}
                    cache={cache}
                />
                </span>
            </td>
        </tr>
    )
}

type ActivityType = "Run" | "Ride" | "Hike";

const ActivityTypeIconMap: { [type in ActivityType]: IconProp } = {
    Run: faRunning,
    Ride: faBiking,
    Hike: faHiking,
}

interface ActivityIconProps {
    activityType: string;
}

const ActivityIcon: React.FC<ActivityIconProps> = ({ activityType }) => {
    return (
        <FontAwesomeIcon
            icon={ActivityTypeIconMap[activityType as ActivityType] || faQuestionCircle}
        />
    )
}

interface ActivityCacheIndicatorProps {
    activityId: number;
    athleteId: number;
    cache?: Cache;
}

const ActivityCacheIndicator: React.FC<ActivityCacheIndicatorProps> = ({ activityId, athleteId, cache }) => {
    const [isCached, setIsCached] = useState<boolean>();

    useEffect(() => {
        if (cache) {
            (async () => {
                setIsCached(await GetCacheMatch(cache, GetActivityUrl(athleteId, activityId, true)));
            })();
        }
    }, [cache, activityId, athleteId]);

    return (
        <FontAwesomeIcon
            icon={isCached === undefined ? faHourglassHalf : isCached ? faCheckCircle : faMinusCircle}
            title={isCached === undefined ? 'loading...' : isCached ? 'Cached, click to refresh' : 'Not cached, click to try to cache'}
            onClick={() => {
                if (cache && isCached !== undefined ) {
                    setIsCached(undefined);
                    (async () => {
                        const url = GetActivityUrl(athleteId, activityId, true);
                        if(isCached) {
                            await cache.delete(url);
                        }
                        await GetFromCacheJSON(cache, url);
                        await DelayForMilliseconds(200);
                        setIsCached(await GetCacheMatch(cache, url));
                        // TODO: somehow signal a refresh of both the server-side activity list and the client-side activity list for the given activity
                    })();
                }
            }}
        />
    );
}
