import { faFlagUsa } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {scaleLinear} from "d3";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import SimplyGraph, { GraphLine } from "src/lib/d3/SimplyGraph";
import { parseGPXData } from "src/lib/Utils/GPX";
import { MeterDistanceBetweenLatitudeLongitude } from "src/lib/Utils/Maps";
import { MetersToFeet, MetersToMiles } from "../Running/MapboxUtils/RouteDetails";

const GPXRouteComparison: React.FC = () => {
	const [graphLines, setGraphLines] = useState<GraphLine<any>[]>();
	const [metric, setMetric] = useState(true);

	const load = useCallback(async () => {
		const gpxFileNames = [
			'boston',
			'paris-1',
			'paris-2'
		];

		Promise.all(gpxFileNames.map(async (name) => {
			const gpxData = parseGPXData(await (await fetch(`/data/${name}.gpx`)).text());
			const initialPoint = gpxData[0];
			let {
				altitude: initialAltitude,
				lon: previousLongitude,
				lat: previousLatitude,
			} = initialPoint;
			let totalDistance = 0;
			const dataPoints = [];
			for (var index = 0; index < gpxData.length; index++) {
				const datum = gpxData[index];
				const metricDistance = MeterDistanceBetweenLatitudeLongitude(
					{
						lat: previousLatitude,
						lon: previousLongitude
					},
					{
						lat: datum.lat,
						lon: datum.lon
					}
				) + totalDistance;
				const metricAltitude = datum.altitude - initialAltitude;
				
				dataPoints.push({
					metricAltitude,
					imperialAltitude: MetersToFeet(metricAltitude),
					// TODO: fix x to be actual distance between lat long points
					metricDistance: metricDistance / 1000,
					imperialDistance: MetersToMiles(metricDistance),
				});

				previousLongitude = datum.lon;
				previousLatitude = datum.lat;
				totalDistance = metricDistance;
			}

			return { name, dataPoints };
		})).then(p => {
			setGraphLines(p);
		})
	}, []);
	
	useEffect(() => {
		load();
	}, [load]);
	
	return graphLines ?
		<>
			<div style={{ margin: '5px', position: 'fixed' }}>
				<Button
					value="metric"
					variant="outline-secondary"
					size="sm"
					active={!metric}
					onClick={() => setMetric(!metric)}
				>
					<FontAwesomeIcon icon={faFlagUsa}/>
				</Button>
			</div>
			<SimplyGraph
				graphLines={graphLines}
				xScaleOverride={scaleLinear()}
				yAxisLabel="altitude"
				xAxisLabel="distance"
				xAccessor={metric ? 'metricDistance' : 'imperialDistance'}
				yAccessor={metric ? 'metricAltitude' : 'imperialAltitude'}
				xAxisFormat={(d: number) => `${d} ${metric ? 'km' : 'mi'}`}
				yAxisFormat={(d: number) => `${d.toFixed()} ${metric ? 'm': 'ft'}`}
				omitLastDataLabel={true}
				margins={{top: 10, left:75, right: 10, bottom: 35}}
			/>
		</>
		: <h1>hello</h1>
}

export default GPXRouteComparison;