import { useCallback, useEffect, useRef, useState } from "react";
import { ActivityData, CityNodeFeature, CityWayV2 as CityWayBase, DataViewPropsBase } from "../types";
import toast from "react-hot-toast";
import { CACHE_NAME, GetAthleteCityNodesUrl, GetAthleteCityPathsUrl, GetAthleteCityWayMapUrl, GetCityNodesUrl, GetFromCacheJSON, RequiredNodesForWay } from "../Common";
import { CITY_MAPPING } from "../HeatmapUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup, FormControl, Button, Table, Tab, Nav } from "react-bootstrap";
import SimplyGraph from "src/lib/d3/SimplyGraph";
import ActivityModal from "../ActivityModal";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import RunTable from "./RunTable";
import StreetsTable from "./StreetsTable";
import * as d3 from "d3";
import SimplyPlot from "src/lib/d3/SimplyPlot";

const Wrapper: React.FC<DataViewPropsBase> = ({
    athleteId,
    activities,
    statistics
}) => {
    const cache = useRef<Cache>();
    const [cacheReady, setCacheReady] = useState(false);

    useEffect(() => {
        (async () => {
            cache.current = await window.caches.open(CACHE_NAME);
            setCacheReady(true);
        })();
    }, []);

    console.log(cache.current && cacheReady)
    return (cache.current && cacheReady)
        ? (
            <NodesProgress
                cache={cache.current}
                athleteId={athleteId}
                activities={activities}
                statistics={statistics}
            />
        ) : null;
}

export interface Progress {
    date: Date;
    id: number;
    name: string;
    distance: number;
    ways: {
        completed: number;
        completedPercent: number;
        runningCompleted: number;
        runningCompletedPercent: number;
        progressed: number;
        completedList: string[];
        progressedList: string[];
    };
    nodes: {
        completed: number;
        completedList: CityNode[];
        completedPercent: number;
        runningCompleted: number;
        runningCompletedPercent: number;
    };
    wayPercents: {
        completed: number;
        runningCompleted: number;
    }
}

export interface CityNode {
    ways: number[];
    id: number;
    activityId: number | undefined;
    coordinates: [number, number];
}

export interface CityWay extends CityWayBase {
    nodes: number[][];
    nodeInformation: CityNode[];
}

interface NodesProgressProps extends DataViewPropsBase {
    cache: Cache;
}

const NodesProgress: React.FC<NodesProgressProps> = ({
    athleteId,
    activities,
    cache,
}) => {
    const [cityId, setCityId] = useState(parseInt(localStorage.getItem('selectedCity') || '-1'));
    const [nodesProgress, setNodesProgress] = useState<Progress[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [modalActivity, setModalActivity] = useState<ActivityData>();
    const [cityWays, setCityWays] = useState<{[key: string]: CityWay }>();
    const [totalNodes, setTotalNodes] = useState(1);

    const loadCity = useCallback(async () => {
        const nodesProgress: Progress[] = [];
        const cityName = CITY_MAPPING[cityId].name;
        const t = toast.loading(`Loading ${cityName} Nodes...`);
        const nodesUrl = GetAthleteCityNodesUrl(athleteId, cityId);
        const cityNodes: CityNodeFeature[] = await GetFromCacheJSON(cache, nodesUrl);

        const activityToNodesFoundMap = cityNodes.reduce(
            (accumulator: { [activity: number]: CityNode[]}, node: CityNodeFeature) => {
                const { activityId, ways, id } = node.properties;
                if (activityId !== 0 && activityId !== undefined) {
                    if (!accumulator.hasOwnProperty(activityId)) {
                        accumulator[activityId] = [];
                    }
                    accumulator[activityId].push({
                        ways,
                        id,
                        activityId,
                        coordinates: node.geometry.coordinates as [number,number],
                    });
                }
                return accumulator;
            }, {});

        const cityWayMapUrl = GetAthleteCityWayMapUrl(athleteId, cityId);
        toast.loading(`Loading ${cityName} way details...`, { id : t });
        let cityWayMap: { [key: string]: CityWay };
        try {
            cityWayMap = await GetFromCacheJSON(cache, cityWayMapUrl);
        } catch (e) {
            console.warn('Exception getting city way map: ', e);
            await cache.delete(cityWayMapUrl);
            toast.error(`Exception getting city way map for ${cityName}`, { id: t });
            return;
        }
        const wayIdToNameMap = Object.keys(cityWayMap).reduce(
            (accumulator: { [id: number]: string }, wayName) => {
                const ids = cityWayMap[wayName].ids || [];
                for (var i = 0; i < ids.length; i++) {
                    accumulator[ids[i]] = wayName;
                }
                cityWayMap[wayName].nodeInformation = (cityWayMap[wayName].nodes || []).reduce(
                    (accumulator: CityNode[], way) => {
                        const wayNodes = way.map(node => {
                            const nodeInformation = cityNodes[node];
                            if (!nodeInformation) {
                                console.warn(`Bad node index ${node} for way ${wayName} with nodes ${cityWayMap[wayName]}`);
                            }
                            const { id, ways, activityId } = nodeInformation.properties;
                            return {
                                id,
                                ways,
                                activityId,
                                coordinates: nodeInformation.geometry.coordinates as [number,number],
                            };
                        });
                        Array.prototype.push.apply(accumulator, wayNodes);
                        return accumulator;
                }, []);
                return accumulator;
        }, {});

        toast.loading('Processing way details...', { id: t });
        const actualWayNames = Object.keys(cityWayMap);
        
        const activityToWaysCompletedMap: { [key: number] : string[] } = {};
        for (var i = 0; i < actualWayNames.length; i++) {
            const wayName = actualWayNames[i];
            const { complete, completedBy } = cityWayMap[wayName];
            if (complete && completedBy) {
                if (!activityToWaysCompletedMap.hasOwnProperty(completedBy)) {
                    activityToWaysCompletedMap[completedBy] = [];
                }
                activityToWaysCompletedMap[completedBy].push(wayName);
            }
        }
        console.log(cityWayMap)

        toast.loading('Processing activities...', { id: t });
        let currentCompletedWays = 0;
        let currentNodesCompleted = 0;
        let currentWayPercentComplete = 0;
        const totalWays = actualWayNames.length;

        for (var activityIndex = 0; activityIndex < activities.length; activityIndex++) {
            const activity = activities[activityIndex];
            const activityId = activity.id;
            
            const waysCompletedList = activityToWaysCompletedMap[activityId] || [];
            const waysCompleted = waysCompletedList.length;
            const nodesCompletedByActivity = activityToNodesFoundMap[activityId] || [];
            if (nodesCompletedByActivity.length === 0) {
                continue
            }
            const waysProgressedByActivity: string[] = [];
            for (var nodeIndex = 0; nodeIndex < nodesCompletedByActivity.length; nodeIndex++) {
                const { ways } = nodesCompletedByActivity[nodeIndex];
                for (var wayIndex = 0; wayIndex < ways.length; wayIndex++) {
                    const wayId = ways[wayIndex];
                    const wayName = wayIdToNameMap[wayId];
                    const way = cityWayMap[wayName];
                    if (way === undefined) {
                        console.warn('wut: wayName:', wayName);
                        continue;
                    }
                    if (waysProgressedByActivity.indexOf(wayName) < 0 &&
                        (!way.completedBy || way.completedBy === activityId)) {
                        waysProgressedByActivity.push(wayName);
                    }
                }
            }

            const percentCompletedByActivityPerWay = Array.from(waysProgressedByActivity).map((wayName) => {
                const way = cityWayMap[wayName];
                if (way.completedBy && way.completedBy < activityId) {
                    return 0
                }
                const totalNodesNeeded = RequiredNodesForWay(way.completed + way.incompleted);
                const wayNodesCompletedByActivity = (way.nodeInformation || []).filter(node => {
                    return node.activityId === activityId;
                }).length;
                return Math.min(wayNodesCompletedByActivity, totalNodesNeeded) / totalNodesNeeded;
            });
            const wayPercentCompleted = percentCompletedByActivityPerWay.reduce((t, i) => t + i, 0);
            const nodesCompleted = nodesCompletedByActivity.length;
            currentWayPercentComplete += wayPercentCompleted;
            currentCompletedWays += waysCompleted;
            currentNodesCompleted += nodesCompleted;
            
            nodesProgress.push({
                date: activity.date,
                id: activity.id,
                name: activity.name,
                distance: activity.distance,
                ways: {
                    completed: waysCompleted,
                    completedPercent: (waysCompleted / totalWays) * 100,
                    runningCompleted: currentCompletedWays,
                    progressed: percentCompletedByActivityPerWay.length,
                    runningCompletedPercent: (currentCompletedWays / totalWays) * 100,
                    completedList: waysCompletedList,
                    progressedList: waysProgressedByActivity.filter(way => {
                        return waysCompletedList.indexOf(way) < 0;
                    }),
                },
                wayPercents: {
                    completed: (wayPercentCompleted / actualWayNames.length) * 100,
                    runningCompleted: (currentWayPercentComplete / actualWayNames.length) * 100,
                },
                nodes: {
                    completed: nodesCompleted,
                    completedPercent: (nodesCompleted / cityNodes.length) * 100,
                    runningCompleted: currentNodesCompleted,
                    runningCompletedPercent: (currentNodesCompleted / cityNodes.length) * 100,
                    completedList: nodesCompletedByActivity
                },
            });
        }
        toast.success('Loading Complete!', { id: t, duration: 6000 });
        setNodesProgress(nodesProgress);
        setCityWays(cityWayMap);
        setTotalNodes(cityNodes.length);
    }, [activities, athleteId, cache, cityId]);

    useEffect(() => {
        localStorage.setItem('selectedCity', cityId.toString());
    }, [cityId]);

    useEffect(() => {
        loadCity();
    }, [loadCity]);

    if (activities.length === 0) {
        return (<h4>No activities selected!</h4>);
    }

    const latestProgress = nodesProgress[nodesProgress.length - 1];
    if (!latestProgress) {
        return null;
    }
    const totalRuns = nodesProgress.length;
    const totalDistance = nodesProgress.reduce((accumulator, progress) => accumulator + progress.distance, 0);
    const totalWays = Object.keys(cityWays || {}).length;
    const totalWaysPercentage = totalWays * 100;

    const totalNodePercentage = nodesProgress.reduce((accumulator, progress) => accumulator + progress.nodes.completedPercent, 0);
    const totalWaysCompletedPercent = nodesProgress.reduce((accumulator, progress) => accumulator + progress.ways.completedPercent, 0);
    const totalWayPercentCompletedPercent = nodesProgress.reduce((accumulator, progress) => accumulator + progress.wayPercents.completed, 0);

    const averageNodePercentProgress = totalNodePercentage / totalRuns;
    const averageStreetProgress = totalWaysCompletedPercent / totalRuns;
    const averageStreetPercentProgress = totalWayPercentCompletedPercent/ totalRuns;
    
    const averageNodePercentProgressPerMile = totalNodePercentage / totalDistance;
    const averageStreetProgressPerMile = totalWaysCompletedPercent / totalDistance;
    const averageStreetPercentProgressPerMile = totalWayPercentCompletedPercent / totalDistance;

    return (
        <>
            <div style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '65px'
            }}>
                <div style={{
                    padding: '8px',
                    display: 'inline',
                }}>
                    <span
                        style={{ fontSize: '24px' }}
                    >
                        City Strides Node Progress
                    </span>
                    <InputGroup
                        size="sm"
                        style={{
                            float: 'right',
                            width: 'auto',
                        }}
                    >
                        <FormControl
                            as="select"
                            size="sm"
                            value={cityId.toString()}
                            onChange={(e) => {
                                const currentSelectedCity = parseInt(e.currentTarget.value);
                                if (currentSelectedCity !== -1) {
                                    setCityId(currentSelectedCity);
                                }
                            }}
                            custom={true}
                        >
                            <option
                                key="-"
                                value={-1}
                            >
                                Select A City
                            </option>
                            {
                                Object.keys(CITY_MAPPING).map(cityId => {
                                    const city = CITY_MAPPING[parseInt(cityId)];
                                    return (
                                        <option
                                            key={cityId}
                                            value={cityId.toString()}
                                        >
                                            {city.name}
                                        </option>
                                    );
                                })
                            }
                        </FormControl>
                        <InputGroup.Append>
                            <Button
                                variant="warning"
                                onClick={async () => {
                                    await cache.delete(GetCityNodesUrl(cityId));
                                    await cache.delete(GetAthleteCityPathsUrl(athleteId, cityId));
                                    await cache.delete(GetAthleteCityWayMapUrl(athleteId, cityId));
                                    await cache.delete(GetAthleteCityNodesUrl(athleteId, cityId));
                                    loadCity();
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faRedoAlt}
                                    style={{ marginRight: '4px' }}
                                />
                                Refresh Nodes

                            </Button>                        
                        </InputGroup.Append>
                    </InputGroup>
                </div>
                <Tab.Container
                    id="NodesProgressTabs"
                    transition={false}
                    defaultActiveKey="Overview"
                >
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="Overview">Overview</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Streets">Streets</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Runs">Runs</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content style={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
                        <Tab.Pane eventKey="Overview">
                            <div>
                                <h5>Averages</h5>
                                {latestProgress && (
                                    <Table
                                        size="sm"
                                        striped={true}
                                        hover={true}
                                        style={{ fontFamily: 'monospace' }}
                                    >
                                        <tbody>
                                            <tr>
                                                <th>Per Run</th>
                                                <th style={{ textAlign: 'right' }}>%</th>
                                            </tr>
                                            <tr style={{ color: '#630' }}>
                                                <td>Streets</td>
                                                <td style={{ textAlign: 'right' }}>{averageStreetProgress.toFixed(4)}%</td>
                                            </tr>
                                            <tr style={{ color: '#360' }}>
                                                <td>Streets %</td>
                                                <td style={{ textAlign: 'right' }}>{averageStreetPercentProgress.toFixed(4)}%</td>
                                            </tr>
                                            <tr style={{ color: '#036' }}>
                                                <td>Nodes</td>
                                                <td style={{ textAlign: 'right' }}>{averageNodePercentProgress.toFixed(4)}%</td>
                                            </tr>
                                            <tr>
                                                <th>Per Mile</th>
                                                <th style={{ textAlign: 'right' }}>%</th>
                                            </tr>
                                            <tr style={{ color: '#630' }}>
                                                <td>Streets</td>
                                                <td style={{ textAlign: 'right' }}>{averageStreetProgressPerMile.toFixed(4)}%</td>
                                            </tr>
                                            <tr style={{ color: '#360' }}>
                                                <td>Streets %</td>
                                                <td style={{ textAlign: 'right' }}>{averageStreetPercentProgressPerMile.toFixed(4)}%</td>
                                            </tr>
                                            <tr style={{ color: '#036' }}>
                                                <td>Nodes</td>
                                                <td style={{ textAlign: 'right' }}>{averageNodePercentProgressPerMile.toFixed(4)}%</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div>
                                <h5>Completion Totals</h5>
                                <Table
                                    size="sm"
                                    striped={true}
                                    hover={true}
                                    style={{ fontFamily: 'monospace', textAlign: 'right' }}
                                >
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'left' }}>Statistic</th>
                                            <th>Total</th>
                                            <th>Completed</th>
                                            <th>Remaining</th>
                                            <th>%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ color: '#630' }}>
                                            <td style={{ textAlign: 'left' }}>Streets</td>
                                            <td>{totalWays}</td>
                                            <td>{latestProgress.ways.runningCompleted}</td>
                                            <td>{totalWays - latestProgress.ways.runningCompleted}</td>
                                            <td>{latestProgress.ways.runningCompletedPercent.toFixed(2)} %</td>
                                        </tr>
                                        <tr style={{ color: '#360' }}>
                                            <td style={{ textAlign: 'left' }}>Streets %</td>
                                            <td>{totalWaysPercentage}</td>
                                            <td>{((latestProgress.wayPercents.runningCompleted / 100) * totalWaysPercentage).toFixed(2)}</td>
                                            <td>{(totalWaysPercentage - ((latestProgress.wayPercents.runningCompleted / 100) * totalWaysPercentage)).toFixed(2)}</td>
                                            <td>{latestProgress.wayPercents.runningCompleted.toFixed(2)} %</td>
                                        </tr>
                                        <tr style={{ color: '#036' }}>
                                            <td style={{ textAlign: 'left' }}>Nodes</td>
                                            <td>{totalNodes}</td>
                                            <td>{latestProgress.nodes.runningCompleted}</td>
                                            <td>{totalNodes - latestProgress.nodes.runningCompleted}</td>
                                            <td>{latestProgress.nodes.runningCompletedPercent.toFixed(2)} %</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Streets" style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
                            <div style={{ height: '35%', display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <h5>Cumulative Progress</h5>
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                    <SimplyGraph
                                        forceYDomain={[0, 100]}
                                        yAxisFormat={(a: number) => `${a.toFixed(1)}%`}
                                        graphLines={[
                                            {
                                                dataPoints: nodesProgress.map(progress => {
                                                    return { x: progress.date, y: progress.nodes.runningCompletedPercent };
                                                }),
                                                name: 'Nodes',
                                            },
                                            { 
                                                dataPoints: nodesProgress.map(progress => {
                                                    return { x: progress.date, y: progress.ways.runningCompletedPercent };
                                                }),
                                                name: 'Streets',
                                            },
                                            {
                                                dataPoints: nodesProgress.map(progress => {
                                                    return { x: progress.date, y: progress.wayPercents.runningCompleted };
                                                }),
                                                name: 'Streets %',
                                            },
                                        ]}
                                        xAxisFormat={d3.timeFormat("%Y")}
                                    />
                                </div>
                            </div>
                            <div style={{ overflow: 'scroll', height: '65%' }}>
                                {cityWays && <StreetsTable cityWays={cityWays} />}
                            </div>
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey="Runs"
                            style={{ overflow: 'hidden', width: '100%', height: '100%' }}
                            mountOnEnter={true}
                        >
                            <div style={{ height: '35%', display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <h6>Run Progress</h6>
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                    <SimplyPlot
                                        series={[
                                            {
                                                name: 'Nodes',
                                                data: nodesProgress.map(progress => {
                                                    return [
                                                        progress.date,
                                                        progress.nodes.completedPercent,
                                                        progress.id
                                                    ]
                                                }),
                                            },
                                            {
                                                name: 'Streets',
                                                data: nodesProgress.map(progress => {
                                                    return [
                                                        progress.date,
                                                        progress.ways.completedPercent,
                                                        progress.id
                                                    ]
                                                }),
                                            },
                                            {
                                                name: 'Streets %',
                                                data: nodesProgress.map(progress => {
                                                    return [
                                                        progress.date,
                                                        progress.wayPercents.completed,
                                                        progress.id
                                                    ]
                                                })
                                            },
                                        ]}
                                        colors={d3.scaleOrdinal(['#036','#630', '#360'])}// , ['Nodes', 'Streets', 'Streets %'])}
                                        yAxisFormat={(a: any) => `${(a as number).toFixed(2)} %`}
                                        onClick={(data: [number,number,number]) => {
                                            const clickedActivity = activities.find(a => a.id === data[2]);
                                            if (clickedActivity) {
                                                setModalActivity(clickedActivity);
                                                setShowModal(true);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ overflow: 'scroll', height: '65%' }}>
                                <h4>Stats <small>{nodesProgress.length} (progress) runs</small></h4>
                                {cityWays && <RunTable
                                    progress={nodesProgress}
                                    athleteId={athleteId}
                                    activities={activities}
                                    cityWays={cityWays}
                                    />}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            {modalActivity && (
                <ActivityModal
                    activity={modalActivity}
                    showModal={showModal}
                    onHide={() => { setShowModal(false); }}
                    athleteId={athleteId}
                />
            )}
        </>
    );
}

export default Wrapper;
