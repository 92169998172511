import { CirclePaint, Expression, FillPaint, HeatmapPaint, LinePaint } from "mapbox-gl";
import { MAPBOX_ACCESS_TOKEN_URL } from "./Common";
import { LatitudeLongitude } from "../DewPoint/graph";
import { DirectionsResponse } from "@mapbox/mapbox-sdk/services/directions";
import { TripsLayer } from "deck.gl";

const MAGIC_NUMBER = 78271.484;
const MID_LATITUDE = 45;
const COS_LATITUDE_IN_RADIANS = Math.cos((MID_LATITUDE * Math.PI) / 180);
const NODE_RADIUS_METERS = 25;
export const NODE_RADIUS_PIXELS_FOR_ZOOM = Array.from(Array(23).keys()).map(zoom => {
    const power = Math.pow(2, zoom);
    const T = (NODE_RADIUS_METERS * power) / MAGIC_NUMBER;
    return T / COS_LATITUDE_IN_RADIANS;
});

export const THIN_PATH_PAINT: LinePaint = {
    'line-color': '#80FFFF',
    'line-width': [
        'interpolate',
        ['exponential', 1.99],
        ['zoom'],
        9, NODE_RADIUS_PIXELS_FOR_ZOOM[9] / 2,
        15, NODE_RADIUS_PIXELS_FOR_ZOOM[15] / 2,
        22, NODE_RADIUS_PIXELS_FOR_ZOOM[22] / 2,
    ],
}

export const PATH_PAINT: LinePaint = {
    'line-color': ['get', 'color'],
    'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        ...NODE_RADIUS_PIXELS_FOR_ZOOM.slice(9).reduce(
            (accumulator: number[], pixels: number, index: number) => {
            accumulator.push(index + 9);
            accumulator.push(pixels * 2);
            return accumulator;
        }, []),
    ],
    'line-opacity': [
        "interpolate",
        ["linear"],
        ["zoom"],
        9, 0,
        10, 0.6
    ],
};

export const CITY_COVERAGE_PAINT: FillPaint = {
    'fill-color': ['get', 'color'],
    'fill-opacity': [
        "interpolate",
        ["linear"],
        ["zoom"],
        13, 0.5,
        14, 0.25,
        15, 0.125,
        16, 0.06,
        22, 0
    ]
};

export const GetDefaultHeatmapColor = (): Expression => {
    return [
        "step",
        //"interpolate",
        //["linear"],
        ["heatmap-density"],
        /*
        // GREEN
        0, "rgba(162, 255, 153, 0)",
        0.02, "rgba(100, 220, 90, .99)",
        // 0.4, "#99d8c9",
        // 0.6, "#66c2a4",
        // 0.8, "#2ca25f",
        1, "rgba(87, 179, 79, 1)"
        */
       /*
       // BROWN
        "hsla(60, 100%, 92%, 0)",
       0.0001,
       "hsla(60, 100%, 92%, 0.5)",
       0.44,
       "hsl(40, 98%, 78%)",
       0.67,
       "hsl(32, 99%, 58%)",
       0.86,
       "hsl(24, 88%, 45%)",
       1,
       "#993404"
       */
        // Purple
        "hsla(60, 100%, 92%, 0)",
        0.01,
        "#3f007d",
        0.25,
        "#54278f",
        0.48,
        "#6a51a3",
        0.67,
        "#807dba",
        0.87,
        "#9e9ac8",
        1,
        "#bcbddc"
    ];
}

export const HeatmapLayerPaint = (paintColor: Expression): HeatmapPaint => {
    return {
        "heatmap-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            ...NODE_RADIUS_PIXELS_FOR_ZOOM.reduce(
                (accumulator: number[], pixels: number, index: number) => {
                accumulator.push(index);
                accumulator.push(pixels);
                return accumulator;
            }, []),
        ],
        "heatmap-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            7, 1,
            14, .85,
            19, .85,
            20, 0
        ],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 1,
            19, 1
        ],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        "heatmap-color": paintColor,
    };
}

export const RunNodesPaint: CirclePaint = {
    'circle-radius': {
        'base': 1.75,
        'stops': NODE_RADIUS_PIXELS_FOR_ZOOM.slice(17).reduce(
            (accumulator: [number,number][], pixels: number, index: number) => {
            accumulator.push([index + 17, pixels]);
            return accumulator;
        }, []),
    },
    'circle-color': 'rgba(106, 81, 163, 1)',
    'circle-opacity': [
        "interpolate",
        ["linear"],
        ["zoom"],
        17, 0,
        18, 0.25,
        22, 0.8
    ],
};

const NODES_CIRCLE_RADII = [
 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3,
 3.5, 4.35, 5.86, 8.49, 13.1, 21.17, 35.29, 60
];

export const BASE_ROAD_NODES_CIRCLE_RADIUS: Expression = [
    "interpolate",
    ["exponential", 1.75],
    ["zoom"],
    13, 2,
    14, 3,
    22, 60
];

export const SELECTED_ROAD_NODES_CIRCLE_RADIUS = (selectedIds: string[]): Expression => [
    "interpolate",
    ["linear"],
    ["zoom"],
    ...(
        NODE_RADIUS_PIXELS_FOR_ZOOM.slice(12).reduce(
            (accumulator: any[], pixels: number, index: number) => {
                const zoomLevel = index + 12;
                accumulator.push(zoomLevel);
                accumulator.push([
                    "case", ["in", ["get", "id"], ["literal", selectedIds]],
                    pixels,
                    NODES_CIRCLE_RADII[zoomLevel]
                ]);
                return accumulator;
    }, []))
];

export const RoadNodesPaint: CirclePaint = {
    'circle-color': ['get', 'color'],
    'circle-radius': BASE_ROAD_NODES_CIRCLE_RADIUS,
    'circle-opacity': [
        "interpolate",
        ["linear"],
        ["zoom"],
        0, 0,
        12, 0,
        13, 1
    ],
    'circle-stroke-opacity': [
        "interpolate",
        ["linear"],
        ["zoom"],
        0, 0,
        12, 0,
        13, 1
    ],
    'circle-stroke-width': {
        'base': .25,
        'stops': [
            [13, .3],
            [17, 2],
            [22, 8]
        ]
    },
    'circle-stroke-color': ['get', 'stroke'],
};

export const BASE_LINE_WIDTH: Expression = [
    "interpolate",
    ["exponential", 1.5],
    ["zoom"],
    12.5, 0.5,
    14, 2,
    18, 18
]

export const WaysPaint: LinePaint = {
    'line-width': BASE_LINE_WIDTH,
    'line-color': ['get', 'color']
}

export const SelectedWayPaintLineWidth = () : Expression => [
    'interpolate',
    ['linear'],
    ['zoom'],
    ...NODE_RADIUS_PIXELS_FOR_ZOOM.slice(9).reduce(
        (accumulator: number[], pixels: number, index: number) => {
            const zoomLevel = index + 9;
            accumulator.push(zoomLevel);
            accumulator.push(pixels);
            return accumulator;
    }, []),
];

export const SelectedWaysNamesPaintLineWidth = (selectedWayNames: string[]) : Expression => [
    'interpolate',
    ['linear'],
    ['zoom'],
    ...NODE_RADIUS_PIXELS_FOR_ZOOM.slice(9).reduce(
        (accumulator: any[], pixels: number, index: number) => {
            const zoomLevel = index + 9;
            accumulator.push(zoomLevel);
            accumulator.push([
                "case", ["in", ["get", "name"], ["literal", selectedWayNames]],
                pixels,
                (NODES_CIRCLE_RADII[zoomLevel] * 2)
            ]);
            return accumulator;
    }, []),
];

export const MapboxDrawStyles = [
    // line stroke
    {
        id: 'gl-draw-line',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static']
        ],
        paint: {
            'line-color': 'rgba(0, 0, 0, 0)'
        }
    },
    // vertex points
    {
        id: 'gl-draw-vertex-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static'],
            ['==', '$type', 'Point'],
            ['!=', 'active', 'true']
        ],
        paint: {
            'circle-radius': 6,
            'circle-color': '#3b9ddd',
        }
    },
    {
        id: 'gl-draw-vertex-last',
        type: 'circle',
        filter: ['==', 'user_last', 'true'],
        paint: {
            'circle-radius': 6,
            'circle-color': '#EE2222',
        }
    },
    {
        id: 'gl-dra-vertex-first',
        type: 'circle',
        filter: ['==', 'user_first', 'true'],
        paint: {
            'circle-radius': 6,
            'circle-color': '#22EE22',
        }
    },
    {
        id: 'gl-draw-vertex-active',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static'],
            ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
        ],
        paint: {
            'circle-radius': 6,
            'circle-color': 'rgba(0, 0, 0, 0)',
            'circle-stroke-width': 3,
            'circle-stroke-color': '#FFF',
        }
    }
];

export const GetWalkingDirectionsForCoordinateString = async (coordinatesString: string) => {
    var url = `https://api.mapbox.com/directions/v5/mapbox/walking/${coordinatesString}?geometries=geojson&${MAPBOX_ACCESS_TOKEN_URL}`;
    var response: DirectionsResponse = await (await fetch(url)).json();
    if (response.routes.length > 0) {
        return response.routes[0];
    } else {
      return null;
    }
}

const LongitudeLatitudeToCommaSeparatedString = (longitude: number, latitude: number) =>
    `${longitude},${latitude}`;

export const LongitudeLatitudeCoordinatesAsString = (coordinates: number[]) => {
    return LongitudeLatitudeToCommaSeparatedString(
    coordinates[0],
    coordinates[1]
    );
}

export interface PathProperties {
    color: string;
    coordinates: number[][];
    activityId: number;
}

export interface TripsData {
    path: number[][],
    timestamps: number[],
    date: Date,
    extent: [number, number],
}

export const IncrementCurrentTimeForSizeAndDuration = (currentTime: number, increment: number, duration: number) => {
    return Math.ceil(currentTime + increment) % duration;
}

export interface City {
    name: string;
    updated_at: number;
    center: LatitudeLongitude;
}

export interface CityData {
    updated_at: number;
}

interface AthleteCityData {
    nodes_updated_at: number;
}

export type CombinedCityData = AthleteCityData & City;

export interface City_deprecated {
    name: string;
    center: LatitudeLongitude;
}

export const CITY_MAPPING: { [city: number]: City_deprecated } = {
    186651: {
        name: 'Mosier, OR',
        center: {
            latitude: 45.6835405,
            longitude: -121.3985181,
        },
    },
    238226: {
        name: 'Lyle, WA',
        center: {
            latitude: 45.6932178,
            longitude: -121.2838202,
        },
    },
    237966: {
        name: 'Bingen, WA',
        center: {
            latitude: 45.7148416,
            longitude: -121.4645173,
        },
    },
    237385: {
        name: 'Seattle, WA',
        center: {
            latitude: 47.630104,
            longitude: -122.314990,
        },
    },
    237440: {
        name: 'Bellingham, WA',
        center: {
            latitude: 48.7538,
            longitude: -122.4717,
        },
    },
    237590: {
        name: 'Ruston, WA',
        center: {
            latitude: 47.2994854,
            longitude: -122.5069470,
        },
    },
    8348295: {
        name: 'Tacoma, WA',
        center: {
            latitude: 47.2495798,
            longitude: -122.4398746,
        },
    },
    237358: {
        name: 'Shoreline, WA',
        center: {
            longitude: -122.3437497,
            latitude: 47.7564667,
        },
    },
    238103: {
        name: 'Esperance, WA',
        center: {
            latitude: 47.7949728,
            longitude: -122.3474828,
        },
    },
    170483: {
        name: 'Caldwell, NJ',
        center: {
            latitude:  40.8398218,
            longitude: -74.2765366,
        },
    },
    3143382: {
        name: 'Malta, NY',
        center: {
            latitude: 42.9711708,
            longitude: -73.7929260,
        },
    },
    237865: {
        name: 'Mount Vernon, WA',
        center: {
            latitude: 48.42095,
            longitude: -122.32681,
        },
    },
    2387624: {
        name: 'Barnstable, MA',
        center: {
            latitude: 41.7001132,
            longitude: -70.2994666,
        },
    },
    237867: {
        name: 'Sedro-Woolley, WA',
        center: {
            latitude: 48.5049940,
            longitude: -122.2351090,
        },
    },
};

export const makeTripsLayer = (
	length: number,
	startIndices: Uint32Array,
	positions: Float32Array,
	timestamps: Float32Array,
	colors: Uint8Array,
	currentTime: number,
    additionalProps: any = {},
) => {
	return makeTripsLayerWithProps(
        length,
        startIndices,
        positions,
        timestamps,
        colors,
        currentTime,
        {
		    id: 'trips',
		    fadeTrail: false,
            ...additionalProps,
        }
    );
}

export const makeTripsHighlightLayer = (
	length: number,
	startIndices: Uint32Array,
	positions: Float32Array,
	timestamps: Float32Array,
	colorsHighlight: Uint8Array,
	currentTime: number,
    additionalProps: any = {},
) => {
	return makeTripsLayerWithProps(
        length,
        startIndices,
        positions,
        timestamps,
        colorsHighlight,
        currentTime,
        {
            id: 'tripsHighlight',
            trailLength: 100,
            ...additionalProps,
        }
    );
}

export const makeTripsLayerWithProps = (
    length: number,
	startIndices: Uint32Array,
	positions: Float32Array,
	timestamps: Float32Array,
	colors: Uint8Array,
	currentTime: number,
    additionalProps: any,
) => {
    return new TripsLayer({
        _pathType: 'open',
        widthMinPixels: 3,
        currentTime,
        data: {
			length,
			startIndices,
			attributes: {
				getPath: {
					value: positions,
					size: 2,
				},
				getTimestamps: {
					value: timestamps,
					size: 1,
				},
				getColor: {
					value: colors,
					size: 3,
				}
			},
		},
        ...additionalProps,
    });
}

export const GenerateTripsAttributesFromTripsData = (TripsData: TripsData[]) => {
    return {
        positions: new Float32Array(TripsData.map(d => d.path).flat(2)),
        timestamps: new Float32Array(TripsData.map(d => d.timestamps).flat()),
        startIndices: new Uint32Array(TripsData.reduce((acc, d) => {
            acc.push(acc[acc.length - 1] + d.path.length);
            return acc;
        }, [0])),
        colors: new Uint8Array(TripsData.map(d => d.path.map(_ => { return [255, 0, 0]; })).flat(2)),
        colorsHighlight: new Uint8Array(TripsData.map(d => d.path.map(_ => { return [255, 255, 255]; })).flat(2)),
        length: TripsData.length,
    };
}